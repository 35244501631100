import React from 'react'
import PropTypes from 'prop-types'

import img2 from "../../../../assets/images/img2.png"
import img3 from "../../../../assets/images/img3.png"
import img4 from "../../../../assets/images/img4.png"
import img5 from "../../../../assets/images/img5.png"
import img6 from "../../../../assets/images/img6.png"
import img7 from "../../../../assets/images/img7.webp"
import img8 from "../../../../assets/images/img8.png"
import img9 from "../../../../assets/images/img9.png"
import img10 from "../../../../assets/images/img10.png"
import img11 from "../../../../assets/images/img11.webp"
import img12 from "../../../../assets/images/img12.webp"
import img14 from "../../../../assets/images/img14.png"
import img15 from "../../../../assets/images/img15.png"
import img16 from "../../../../assets/images/img16.png"


const callouts = [
   
  {
    name: 'Whether you run, cycle, swim or just want to up your endurance game, fuel your competitive spirit with these scientifically formulated products.',
    description: 'ENDURANCE SUPPORT',
    // imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-02-edition-03.jpg',
    imageSrc:img3,
    imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
    href: '/products',
  },
  {
    name: 'Get the most out of your workouts—or your daily errands—with anytime energy powders and drinks designed to help you fuel, focus and finish strong.',
    description: 'ANYTIME ENERGY',
    // imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-02-edition-03.jpg',
    imageSrc:img4,
    imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
    href: '/products',
  },
  {
    name: 'Ready to get your workout on? Our energizing formulas are designed to help you perform at the top of your game.',
    description: 'PRE-WORKOUT',
    // imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-02-edition-03.jpg',
    imageSrc:img5,
    imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
    href: '/products',
  },
  {
    name: 'Daily multivitamins can help cover nutritional gaps in your healthy, balanced diet.',
    description: 'VITAMINS',
    // imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-02-edition-02.jpg',
    imageSrc:img2,
    imageAlt: 'Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.',
    href: '/products',
  },
      {
        name: 'Gold Standard 100% Isolate™ is a premium whey protein isolate blend which has had excess fats and sugars ‘isolated’ out of the product.',
        description: 'GOLD STANDARD 100% ISOLATE',
        // imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-02-edition-03.jpg',
        imageSrc:img6,
        imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
        href: '/products',
      },
      {
        name: 'Made from 100% vegan ingredients with a complete profile of amino acids and no artificial colours or flavours. Plant protein never tasted so good.',
        description: 'GOLD STANDARD 100% PLANT',
        imageSrc:img7,
        imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
        href: '/products',
      },
      {
        name: 'Help kick start your muscle growth and repair with Optimum Nutrition’s Gold Standard 100% Whey™',
        description: 'GOLD STANDARD 100% WHEY',
        imageSrc:img8,
        imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
        href: '/products',
      },
      {
        name: 'Build muscle and support recovery with convenient, high quality protein powders, shakes and snacks.',
        description: 'ALL BUNDLES',
        imageSrc:img9,
        imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
        href: '/products',
      },
      {
        name: 'Think outside the shake. Protein takes many forms, including grab-and-go snacks that taste great and deliver the consistently high quality you expect from Optimum Nutrition®.',
        description: 'SNACKS',
        imageSrc:img10,
        imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
        href: '/products',
      },
      {
        name: 'BCAA 1000 Caps are recommended first thing in the morning and/or during the 30 minutes just before or immediately after training',
        description: 'Branched Chain Amino Acid Support',
        imageSrc:img11,
        imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
        href: '/products',
      },
      {
        name: 'Resistance training is only the first step in building muscle. Adequate rest and nutritional support are vital parts of a muscle building program.',
        description: 'MUSCLE BUILDING',
        imageSrc:img12,
        imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
        href: '/products',
      },
      {
        name: 'Protein & Carb Muscle Recovery Formula with 55 Grams of Protein and 109-116 Grams of Carbohydrates Per Serving.',
        description: 'GOLD STANDARD GAINER',
        imageSrc:img14,
        imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
        href: '/products',
      },
      {
        name: 'High-Protein Weight Gainer with 60 Grams of Protein and 85 Grams of Carbohydrates per serving.',
        description: 'PRO GAINER',
        imageSrc:img15,
        imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
        href: '/products',
      },
      {
        name: '1,250 Calories per Serving to Help Boost Calorie Intake for Muscle Building & Weight Gain Goals.',
        description: 'SERIOUS MASS',
        imageSrc:img16,
        imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
        href: '/products',
      },
  ]

const UserCategories = props => {
  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
          <h2 className="text-2xl font-bold text-gray-900">Recommended Collections</h2>

          <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6  lg:gap-y-6 lg:space-y-0">
            {callouts.map((callout) => (
              <div key={callout.name} className="group relative">
                <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
             
                  <img
                    src={callout.imageSrc}
                    alt={callout.imageAlt}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <h3 className="mt-6 text-sm text-gray-500">
                  <a href={callout.href}>
                    <span className="absolute inset-0" />
                    {callout.name}
                  </a>
                </h3>
                <p className="text-base font-semibold text-gray-900">{callout.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

UserCategories.propTypes = {}

export default UserCategories