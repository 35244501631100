import React from "react";
import { useProductsContext } from "../context/products_context";

const PaymentAlert = () => {
  const { alertType, alertText } = useProductsContext();
  return (
    <div
      className={`py-2 px-3 mb-4 border-transparent rounded-md text-center tracking-widest ${
        alertType === "danger"
          ? "text-red-600 bg-[#F8D7DA]"
          : "text-green-800 bg-green-300"
      }`}
    >
      {alertText}
    </div>
  );
};

export default PaymentAlert;
