import React, { useState } from "react";
import { useCategoriesContext } from "../../../context/categories_context";
import { useUsersContext } from "../../../context/users_context";

const CardAddSubCategory = () => {
  const [subCategoryName, setsubCategoryName] = useState("");
  const [idCategoryParent, setIdCategoryParent] = useState("");
  const { user, token } = useUsersContext();
  const { _id } = user;
  const { addSubCategory, all_categories } = useCategoriesContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    addSubCategory(_id, token, subCategoryName, idCategoryParent);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h6 className="text-gray-600 text-center tracking-wider text-xl font-bold mb-5">
          Sub Categories
        </h6>
        <div className="flex justify-between py-6">
          <input
            type="text"
            className="w-full rounded-tl-md rounded-bl-md border-none bg-gray-100 p-1 focus:rounded-tr-md focus:rounded-br-md focus:ring-[#d1b152]"
            value={subCategoryName}
            onChange={(e) => setsubCategoryName(e.target.value)}
          />
          <select
            name=""
            id="category"
            value={idCategoryParent}
            onChange={(e) => {
              setIdCategoryParent(e.target.value);
            }}
            className="w-full border-gray-300 rounded-lg shadow-sm focus:border-[#B5942F] focus:ring-[#B5942F]"
          >
            <option value="" disabled>
              Select a category name
            </option>
            {all_categories?.map((item, index) => {
              return (
                <option key={index} value={item._id}>
                  {item.name}
                </option>
              );
            })}
          </select>
          <button
            className="bg-[#B5942F] w-16 tracking-widest text-white active:bg-[#a3852a] font-bold uppercase px-1 py-1 rounded-tr-md rounded-br-md shadow hover:shadow-md hover:bg-[#a3852a] outline-none focus:outline-none ease-linear transition-all duration-150"
            type="submit"
          >
            {/* {isEditing ? "Edit" : "Add"} */}
            add
          </button>
        </div>
      </form>
    </div>
  );
};

export default CardAddSubCategory;
