import React from "react";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../../context/global_context";
import { useProductsContext } from "../../../context/products_context";
import { convertBase64, formatPrice } from "../../../utils/helpers";
import imgDefault from "../../../assets/images/default.jpg";

// components

export default function CardOrder() {
  const { status } = useProductsContext();
  const { filtered_orders: orders } = useGlobalContext();
  const {
    filters: { text,},
    updateFilters,
  } = useGlobalContext();

  const { status: stat } = status;

  const statusUpdate = ["All Orders", ...new Set(stat)];

  return (
    <div className="px-5 py-10">
      <h1 className="text-2xl font-medium mb-5 ">Orders</h1>
      <div className="bg-white shadow-md rounded">
        <div className="flex justify-center gap-8 px-14 pt-5">
          {statusUpdate?.map((stat, index) => {
            return (
              <button
                key={index}
                onClick={updateFilters}
                name="statusFilter"
                type="button"
                value={stat}
                className={`block my-1 mx-0 capitalize bg-transparent border-b-2 border-b-transparent tracking-wider font-medium text-[#869BB0] cursor-pointer`}
              >
                {stat}
              </button>
            );
          })}
        </div>
        <div className="px-14">
          <form
            onSubmit={(e) => e.preventDefault()}
            className="flex justify-center items-center"
          >
            <div className="">
              <label htmlFor="table-search" className="sr-only">
                Search
              </label>
              <div className="relative mt-3">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search"
                  name="text"
                  value={text}
                  onChange={updateFilters}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search by customer name"
                />
              </div>
            </div>
          </form>
        </div>
        <hr className="mt-4" />

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-8 px-3">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-400 border-b-2 border-b-black capitalize dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Total
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((order) => {
                 
                
                return (
                  <tr
                    key={order._id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <th
                      scope="row"
                      className="px-6 py-2 font-medium text-gray-900 dark:text-white flex items-center gap-4 whitespace-nowrap"
                    >
                      <img
                        className="rounded-full w-10 object-cover object-center"
                        // src={`data:image/jpeg;base64,${convertBase64(
                        //   user.photo.data.data
                        // )}`}
                        src={
                          order?.user?.photo
                            ? `data:image/jpeg;base64,${convertBase64(
                                order?.user?.photo?.data?.data
                              )}`
                            : imgDefault
                        }
                        alt="Avatar Upload"
                      />
                     
                      <span>{order?.user?.name}</span>
                    </th>
                    <td className="px-6 py-2">{order?.user?.email}</td>
                    <td className="px-6 py-2">{formatPrice(order?.amount)}</td>
                    <td className="px-6 py-2">
                      {new Date(order.createdAt)?.toDateString()}
                    </td>
                    <td className="px-6 py-2">
                      {/* <button className="bg-red-700 text-white rounded-full px-2 py-1">
                        {console.log(order.status)}
                        { order.status } 
                      </button> */}
                      { order.status ==="Processing" && 
                      <button className="bg-orange-400 text-white rounded-full px-4 py-1">Processing</button>
                      || order.status ==="Order placed" &&
                      <button className="bg-yellow-400 text-white rounded-full px-2 py-1">Order Placed</button>
                      || order.status ==="Delivered" &&
                      <button className="bg-green-500 text-white rounded-full px-5 py-1">Delivered</button>
                      || order.status ==="Shipped" &&
                      <button className="bg-green-600 text-white rounded-full px-6 py-1">Shipped</button>
                      || order.status ==="Cancelled" &&
                      <button className="bg-red-700 text-white rounded-full px-5 py-1">Canceled</button>
                      }
                    </td>
                    <td className="px-8 py-2">
                      <Link
                        className="text-base text-yellow-400"
                        to={`/admin/orders/${order._id}`}
                      >
                        <FaEye />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
