import React from "react";
import video from "../assets/video.mp4";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../context/global_context";

const Hero = () => {
  const { closeFavorite } = useGlobalContext();

  return (
    <div onMouseOver={closeFavorite}>
      <div>
        <p className="pt-4 pb-4 px-5 text-xs text-center md:hidden">
          <strong>Buy More Save More</strong> - Save 10% On Orders over $70
        </p>
      </div>
      {/* relative was removed on this div*/}
      <div className=" 2xl:pt-0  pr-0 grid place-items-center md:h-screen ">
        <video
          autoPlay
          muted
          loop
          className="md:absolute  right-0 z-[-1]  min-w-full min-h-full brightness-50 bottom-0 top-0"
        >
          <source src={video} type="video/mp4" />
        </video>
        <div className="w-full absolute md:top-32 px-5 text-center md:text-left md:px-24 ">
          <h1 className="text-white font-bold px-5 md:px-0 text-lg md:text-4xl leading-relaxed md:leading-tight tracking-wide md:w-96 uppercase">
            you're working on more than your body
          </h1>
          <p className="text-gray-400 text-sm md:text-base uppercase tracking-wider mt-3 md:mt-5 mb-2 md:mb-5">
            get better with nutrisport
          </p>
          <Link
            to="/products"
            className="bg-black text-white  px-5 py-3 md:px-12 text-xs lg:text-lg uppercase inline-block tracking-wide hover:bg-[#212121] transition-all duration-500 ease-linear"
          >
            Shop Now
          </Link>
        
        </div>
      </div>
    </div>
  );
};

export default Hero;
