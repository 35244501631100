import React from "react";
import CardAddProduct from "../../Cards/CardAddProduct";

export default function AddProduct() {
  return (
    <>
      <div className="flex justify-center">
        <div className="w-full lg:w-4/5 px-4">
          <CardAddProduct />
        </div>
      </div>
    </>
  );
}
