import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useGlobalContext } from "../context/global_context";

const ContactUs = () => {
  const [visible, setIsVisible] = useState(false);
  const { closeFavorite } = useGlobalContext();

  return (
    <>
      <Navbar bg="bg-gradient-to-l from-gray-800 to-black" />
      {visible && (
        <div
          id="authentication-modal"
          tabIndex="-1"
          className="overflow-y-auto overflow-x-hidden fixed top-20 lg:top-28 lg:left-[28rem] right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                onClick={() => setIsVisible(false)}
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <div className="py-6 px-6 lg:px-8">
                <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                  Contact Us
                </h3>
                <form className="space-y-6" action="#">
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Your name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="aimad"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Your email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="name@company.com"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Your comment
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="fell free"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full text-white bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="py-12 text-center" onMouseOver={closeFavorite}>
        <h1 className="text-4xl font-bold tracking-wider uppercase">
          contact us
        </h1>
        <p className="w-52 my-5 text-[#3B3B41] text-lg mx-auto lg:w-full">
          Have a question? here are all the ways you can reach us.
        </p>
        <div className="bg-[#F2F2F2] mx-4 py-8 px-3  lg:mx-auto lg:w-3/4">
          <p className="w-52 mb-4 mx-auto lg:w-full text-[#3B3B41] text-lg ">
            Can't find the answer to your question?
          </p>
          <h1 className="text-3xl uppercase font-bold mb-10">
            we're here to help
          </h1>
          <div className="grid lg:grid-cols-3">
            <div>
              <svg
                className="my-0 mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
              </svg>
              <h1 className="mt-4 mb-2 font-bold text-lg uppercase">Chat</h1>
              <p className="mb-5">Mon - Fri: 6AM - 6:30PM PT</p>
              <a
                href="https://wa.me/+212677740344"
                className="bg-black text-white w-full inline-block py-2 uppercase rounded-xl text-sm tracking-wider mb-12"
              >
                start chat
              </a>

              {/* <button
                className="bg-black text-white w-full py-2 uppercase rounded-xl text-sm tracking-wider mb-12"
                onClick={() => setIsVisible(true)}
              >
                start chat
              </button> */}
            </div>
            <div>
              <svg
                className="my-0 mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
              </svg>
              <h1 className="mt-4 mb-2 font-bold text-lg uppercase">
                phone support
              </h1>
              <p className="mb-10">
                <strong></strong><strong>+212677740344</strong>
              </p>
            </div>
            <div>
              <svg
                className="my-0 mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </svg>
              <h1 className="mt-4 mb-2 font-bold text-lg uppercase">Email</h1>
              <p className="mb-5">we'll get back to you as soon as we can!</p>
              {/* <a
                href="https://wa.me/+212677740344"
                className="bg-black text-white w-full inline-block py-2 uppercase rounded-xl text-sm tracking-wider mb-12"
              >
                get in touch
              </a> */}
              <button
                className="bg-black text-white w-full py-2 uppercase rounded-xl text-sm tracking-wider mb-12"
                onClick={() => setIsVisible(true)}
              >
                get in touch
              </button> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
