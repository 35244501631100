import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../components/Alert";
import ShowPassword from "../components/ShowPassword";
import { useUsersContext } from "../context/users_context";

const ResetPassword = () => {
  const [password, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { resettoken } = useParams();
  const navigate = useNavigate();
  const { resetPassword, showAlert, displayAlert, alertType } =
    useUsersContext();

  //show password in input
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      displayAlert("passwords does not match");
      return;
    }
    resetPassword(resettoken, password);
  };

  useEffect(() => {
    if (alertType === "success") {
      setTimeout(() => {
        navigate("/signin");
      }, 3000);
    }
  }, [alertType, navigate]);

  return (
    <div className="w-screen h-screen bg-[#F9FAFB] grid place-items-center ">
      <div className=" shadow-md rounded-lg py-16 px-16 bg-[#FFFFFF]">
        <h1 className="text-3xl font-semibold text-center">
          Reset your password
        </h1>
        {showAlert && (
          <div className="mt-3">
            <Alert />
          </div>
        )}
        <form className="mt-8 space-y-3" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor=""
              className="block text-sm font-medium text-gray-700"
            >
              New Password
            </label>
            <div className="mt-3 flex items-center">
              <input
                type={passwordShown ? "text" : "password"}
                className="w-full border-gray-300 rounded-lg shadow-sm focus:border-blue-400 focus:ring-blue-400"
                name="password"
                value={password}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <ShowPassword
                togglePassword={togglePassword}
                passwordShown={passwordShown}
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Confirm Password
            </label>
            <div className="mt-3 flex items-center">
              <input
                type={passwordShown ? "text" : "password"}
                className="w-full border-gray-300 rounded-lg shadow-sm focus:border-blue-400 focus:ring-blue-400"
                name="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <ShowPassword
                togglePassword={togglePassword}
                passwordShown={passwordShown}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="mt-5 font-medium py-2 px-4 border border-transparent rounded-md shadow-sm text-lg text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:ring-2 transition-all duration-500 ease-out "
            >
              Reset password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ResetPassword;
