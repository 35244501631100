import React, { useEffect } from "react";
import { useUsersContext } from "../../../context/users_context";
import OrderList from "./OrderList";

const CardAllUsers = () => {
  const { user, getAllUsers, users } = useUsersContext();

  useEffect(() => {
    getAllUsers(user?._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-20">
      <h1 className="font-semibold mb-2">Users</h1>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Username
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Total Orders
              </th>
              <th scope="col" className="px-6 py-3">
                Total Spending
              </th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map &&
              users.map((user, index) => {
                return (
                  user?.role === 0 && (
                    <tr
                      key={index}
                      className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        {user?.name}
                      </th>
                      <td className="px-6 py-4">{user.email}</td>
                    
                      <td className="px-6 py-4">{user.history.length}</td>
                      <OrderList user={user} />
                      {/* <td></td> */}
                    </tr>
                  )
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CardAllUsers;
