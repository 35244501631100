import React, { useEffect, useContext, useReducer } from "react";
import reducer from "../reducers/cart_reducer";
import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  TOGGLE_CART_ITEM_AMOUNT,
  CLEAR_CART,
  COUNT_CART_TOTALS,
  REMOVE_FAVORITE_ITEM,
  CLEAR_FAVORITE,
  ADD_TO_FAVORITE,
} from "../actions";

const getLocalStorage = () => {
  let cart = localStorage.getItem("cart");
  if (cart) {
    return JSON.parse(localStorage.getItem("cart"));
  } else {
    return [];
  }
};

const getFavoriteFromLocalStorage = () => {
  let favorite = localStorage.getItem("favorite");
  if (favorite) {
    return JSON.parse(localStorage.getItem("favorite"));
  } else {
    return [];
  }
};

const initialState = {
  cart: getLocalStorage(),
  favorite: getFavoriteFromLocalStorage(),
  total_items: 0,
  total_amount: 0,
  shipping_fee: 0,
};

const CartContext = React.createContext();

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  //add to cart
  const addToCart = (_id, amount, product) => {
    dispatch({ type: ADD_TO_CART, payload: { _id, amount, product } });
  };
  //remove item
  const removeItem = (id) => {
    dispatch({ type: REMOVE_CART_ITEM, payload: id });
  };
  //toggle amount
  const toggleAmount = (id, value) => {
    dispatch({ type: TOGGLE_CART_ITEM_AMOUNT, payload: { id, value } });
  };
  //clear cart
  const clearCart = () => {
    dispatch({ type: CLEAR_CART });
  };

  //add to favorite
  const addToFavorite = (_id, product) => {
    dispatch({ type: ADD_TO_FAVORITE, payload: { _id, product } });
  };
  //remove item
  const removeFromFavorite = (id) => {
    dispatch({ type: REMOVE_FAVORITE_ITEM, payload: id });
  };
  //clear cart
  const clearFavorite = () => {
    dispatch({ type: CLEAR_FAVORITE });
  };

  useEffect(() => {
    dispatch({ type: COUNT_CART_TOTALS });
    localStorage.setItem("cart", JSON.stringify(state.cart));
  }, [state.cart]);

  useEffect(() => {
    // dispatch({ type: COUNT_FAVORITE_TOTALS });
    localStorage.setItem("favorite", JSON.stringify(state.favorite));
  }, [state.favorite]);

  return (
    <CartContext.Provider
      value={{
        ...state,
        addToCart,
        removeItem,
        toggleAmount,
        clearCart,
        addToFavorite,
        removeFromFavorite,
        clearFavorite,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
// make sure use
export const useCartContext = () => {
  return useContext(CartContext);
};
