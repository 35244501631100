import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
// import img2 from "../assets/images/img2.png"
// import img3 from "../assets/images/img3.png"
// import img4 from "../assets/images/img4.png"
import img2 from "../assets/images/car1.jpg"
import img3 from "../assets/images/car2.jpg"
import img4 from "../assets/images/car3.jpg"
import img5 from "../assets/images/car4.jpg"

const settings = {
  dotsclass: "slick-dots slick-thumb",
 infinite: true,
 slidesToShow: 1,
 speed: 8000,
 slidesToScroll: 1,
 autoplay: true,
 autoplaySpeed: 0,
 cssEase: 'linear'
 };

 const Pictures=[
  {
    img:img2
  },
  {
    img:img3
  },
  {
    img:img4
  },
  {
    img:img5
  }
 ]

const Carasoul = () => {
  return (
  
    <Slider className="overflow-visible  image-container" {...settings}>
        {Pictures?.map((p, index) => {
          return (
           
            <div key={index} className=" overflow-hidden ">

                <LazyLoad height={660}>    
                          <img
                            className="h-screen w-full object-cover object-center  bg-neutral-300 "
                            src={`${p.img}`}
                            alt=""
                          /> 
               
                </LazyLoad>
                
             
            </div>

          );
        })}

</Slider>

  )
}

export default Carasoul