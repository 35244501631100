import React from "react";
import { Link } from "react-router-dom";
import CartContent from "../components/CartContent";
import Navbar from "../components/Navbar";
import { useCartContext } from "../context/cart_context";
import cartImg from "../assets/images/cartEmpty.png";
const CartPage = () => {
  const { cart } = useCartContext();
  if (cart.length < 1) {
    return (
      <>
        <Navbar bg="bg-black" />
        <div className="h-screen w-full bg-[#333333]">
          <h1 className="bg-[#3D3D3D] text-white text-5xl pl-8 lg:pl-24 py-5">
            Cart
          </h1>
          <div className="grid place-items-center">
            <div className="w-56">
              <img src={cartImg} alt="" className="w-full object-cover" />
            </div>
            <h2 className="text-white text-3xl tracking-wider mb-5">
              Your cart is empty
            </h2>
            <Link
              to="/products"
              className="bg-yellow-300 px-3 font-medium leading-loose"
            >
              Return to shop
            </Link>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Navbar bg="bg-black" />
      <div>
        <CartContent />
      </div>
    </>
  );
};

export default CartPage;
