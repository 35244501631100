import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

const AmountButtons = ({ amount, increase, decrease }) => {
  return (
    <div className="grid w-36 items-center grid-cols-[1fr_1fr_1fr]">
      <button
        type="button"
        onClick={decrease}
        className="cursor-pointer py-4 px-0 w-8 h-4 flex items-center justify-center"
      >
        <FaMinus />
      </button>
      <h2 className="mb-0 text-2xl flex w-8 items-center justify-center text-[#102A42] font-bold">
        {amount}
      </h2>
      <button
        onClick={increase}
        className="cursor-pointer py-4 px-0 w-8 h-4 flex items-center justify-center"
        type="button"
      >
        <FaPlus />
      </button>
    </div>
  );
};

export default AmountButtons;
