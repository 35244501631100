import React from "react";
import { MdOutlineShoppingCart } from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import Navbar from "../components/Navbar";
import PageHero from "../components/PageHero";
import { useCartContext } from "../context/cart_context";
import { convertBase64, formatPrice } from "../utils/helpers";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import LazyLoad from "react-lazy-load";
import { imgUrl } from "../utils/constants";
import { TbBrandBooking } from "react-icons/tb";


const settings = {
  dotsClass: "slick-dots slick-thumb",
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const FavoritePage = () => {
  const { favorite, addToCart, removeFromFavorite } = useCartContext();

  return (
    <>
      <Navbar bg="bg-gradient-to-l from-gray-800 to-black"/>

      <div className="pb-10">
        <h1 className="text-center tracking-wider mt-5 lg:my-10 text-3xl font-semibold">
          Your Wishlist
        </h1>
        <div className="flex justify-between items-center">
          <PageHero title="favorite" />
          <h1 className="px-5 md:px-20 ">0{favorite.length} items</h1>
        </div>
        {favorite.length < 1 && (
          <div className="text-center space-y-5">
            <h1 className="text-2xl font-semibold">your wish list is empty</h1>
            <Link
              className="underline capitalize inline-block text-gray-400 font-semibold"
              to="/products"
            >
              return to products
            </Link>
          </div>
        )}

        <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-4 lg:gap-8 px-5 lg:px-20">
          {favorite.map((item) => {
           
            const product = { ...item };
            return (
              <div key={item.id} className="bg-white">
                <div className="relative">
                  <Slider
                    className="overflow-visible image-container"
                    {...settings}
                  >
                    {item.productPictures?.map((p, index) => {

                      return (
                        <div key={index} className="rounded-xl overflow-hidden">
                          <Link to={`/products/${item.id}`}>
                            <LazyLoad height={224}>
                              <img
                                className="h-56 w-full object-cover object-center"
                                // src={`${imgUrl}/uploads/${p.img}`}
                                src={`${p.img}`}
                                // src={`data:image/jpeg;base64,${p.img}`}
                                alt=""
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      );
                    })}
                  </Slider>
                  <button
                    className="absolute top-2 right-2 text-2xl lg:text-xl bg-white rounded-full p-1 shadow-md"
                    onClick={() => removeFromFavorite(item.id)}
                  >
                    <BsTrash />
                  </button>
                </div>
                <div className="mt-5 space-y-1">
                  <h1 className="font-medium ">{item.name}</h1>
                  <h1 className="font-semibold">
                    {formatPrice(item.price)}{" "}
                    <span className="font-light">night</span>
                  </h1>
                  <Link
                    to={`/products/${item.id}`}
                    className="flex items-center rounded-xl bg-black text-white w-full py-1 justify-center gap-2"
                  >
                    <span className="text-xl">
                      {/* <TbBrandBooking /> */}
                    </span>
                    <span>Shop Now !</span>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FavoritePage;
