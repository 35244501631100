import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "flowbite";
import { UsersProvider } from "./context/users_context";
import { CategoriesProvider } from "./context/categories_context";
import { ProductsProvider } from "./context/products_context";
import { AppProvider } from "./context/global_context";
import { FilterProvider } from "./context/filters_context";
import { CartProvider } from "./context/cart_context";
import { ContextProvider } from "./context/ContextProvider";



ReactDOM.render(
  <CategoriesProvider>
    <ProductsProvider>
      <FilterProvider>
        <UsersProvider>
          <CartProvider>
            <AppProvider>
              <ContextProvider>
                  <App /> 
              </ContextProvider>
            </AppProvider>
          </CartProvider>
        </UsersProvider>
      </FilterProvider>
    </ProductsProvider>
  </CategoriesProvider>,
  document.getElementById("root")
);
