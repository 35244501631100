import React from "react";
import { convertBase64, formatPrice } from "../../../utils/helpers";

const OrderProductList = ({ single_order }) => {
  return (
    <div>
      {single_order?.products?.map((product) => {
        console.log(single_order)
        return (
          <div key={product._id} className="flex">
            <div className="w-32">
              <img
                className="w-full"
                // src={`data:image/jpeg;base64,${convertBase64(
                //   product?.photo.data?.data
                // )}`}
                src={product.productPictures[0].img}
                alt={product.name}
              />
            </div>
            <div className="space-y-3">
              <h1 className="font-semibold">{product.name}</h1>
              <h1 className="font-medium">QTE : {product.amount}</h1>
              <h1 className="text-gray-500 font-medium">
                {formatPrice(product.price)}
              </h1>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrderProductList;
