import React from 'react'
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useProductsContext } from "../../../../context/products_context";
import { useUsersContext } from "../../../../context/users_context";
import { convertBase64, formatPrice } from "../../../../utils/helpers";
import Loading from "../../../Loading";
import imgDefault from "../../../../assets/images/default.jpg";
const SingleOrder = () => {
  const { idOrder } = useParams();
  const { getSingleOrder, single_order, getStatus, updateStatus, status } =
    useProductsContext();
  const { user, token } = useUsersContext();
  const { status: stat } = status;

  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    getSingleOrder(user._id, token, idOrder);
    // eslint-disable-next-line
  }, [idOrder]);

  useEffect(() => {
    getStatus(user._id, token);
    // eslint-disable-next-line
  }, []);

    // !single_order && <Loading />;

  const handleSubmit = (e) => {
    e.preventDefault();
    updateStatus(user._id, token, idOrder, selectedStatus);
  };

  return (
    <div className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
      <div className="flex justify-start item-start space-y-2 flex-col">
        <h1 className="text-3xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800">
          Order {single_order?._id?.substr(single_order?._id.length - 4)}
        </h1>
        <p className="text-base dark:text-gray-300 font-medium leading-6 text-gray-600">
          {new Date(single_order?.createdAt)?.toDateString()}
        </p>
      </div>
      <div className="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
        <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
          <div className="flex flex-col justify-start items-start dark:bg-gray-800 bg-white shadow-md rounded-md px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
            <p className="text-lg md:text-xl dark:text-white font-semibold leading-6 xl:leading-5 text-gray-800">
              Customer’s Cart
            </p>
            {single_order?.products?.map((product) => {
              return (
                <div
                  key={product._id}
                  className="mt-4 md:mt-6 flex flex-col md:flex-row justify-start items-start md:items-center md:space-x-2 xl:space-x-4 w-full"
                >
                  <div className="pb-4 md:pb-8 w-full md:w-40">
                    <img
                      className="w-full hidden md:block object-cover"
                      // src={`data:image/jpeg;base64,${convertBase64(
                      //   product.photo.data.data
                      // )}`}
                      src={product?.productPictures[0]?.img}
                      alt="dress"
                    />
                    <img
                      className="w-full md:hidden"
                      // src={`data:image/jpeg;base64,${convertBase64(
                      //   product.photo.data.data
                      // )}`}
                      src={product?.productPictures[0]?.img}
                      alt="dress"
                    />
                  </div>
                  <div className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full pb-8 space-y-4 md:space-y-0">
                    <div className="w-full flex flex-col justify-start items-start space-y-8">
                      <h3 className="text-xl dark:text-white xl:text-xl lowercase font-semibold text-gray-800">
                        {product?.name}
                      </h3>
                    </div>
                    <div className="flex justify-between space-x-8 items-start w-full">
                      <p className="text-base dark:text-white xl:text-lg leading-6">
                        {product?.amount}
                      </p>
                      <p className="text-base dark:text-white xl:text-lg leading-6 text-gray-800">
                        {formatPrice(product?.price)}
                      </p>
                      <p className="text-base dark:text-white xl:text-lg font-semibold leading-6 text-gray-800">
                        {formatPrice(product.amount * product.price)}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-center md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
            <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-white rounded-md shadow-md dark:bg-gray-800 space-y-6">
              <h3 className="text-xl dark:text-white font-semibold leading-5 text-gray-800">
                Summary
              </h3>
              <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                <div className="flex justify-between w-full">
                  <p className="text-base dark:text-white leading-4 text-gray-800">
                    Subtotal
                  </p>
                  <p className="text-base dark:text-gray-300 leading-4 text-gray-600">
                    {formatPrice(single_order.amount)}
                  </p>
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-base dark:text-white leading-4 text-gray-800">
                    Shipping
                  </p>
                  <p className="text-base dark:text-gray-300 leading-4 text-gray-600">
                    $0.00
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center w-full">
                <p className="text-base dark:text-white font-semibold leading-4 text-gray-800">
                  Total
                </p>
                <p className="text-base dark:text-gray-300 font-semibold leading-4 text-gray-600">
                  {formatPrice(single_order.amount)}
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center px-4 py-6 md:p-6 xl:p-8 w-full bg-white rounded-md shadow-md dark:bg-gray-800 space-y-6">
              <h3 className="text-xl dark:text-white font-semibold leading-5 text-gray-800">
                Update Status
              </h3>
              <form action="" onSubmit={handleSubmit}>
                <select
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  value={selectedStatus}
                  id="countries"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="" disabled>
                    Select a status
                  </option>
                  {stat?.map((s, index) => {
                    return (
                      <option key={index} value={s}>
                        {s}
                      </option>
                    );
                  })}
                </select>
                <div className="w-full flex justify-center items-center mt-5">
                  <button
                    type="submit"
                    className="hover:bg-black dark:bg-white dark:text-gray-800 dark:hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-5 w-96 md:w-full bg-gray-800 text-base font-medium leading-4 text-white"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-md shadow-md dark:bg-gray-800 w-full xl:w-96 h-full flex justify-between items-center md:items-start px-4 py-6 md:p-6 xl:p-8 flex-col">
          <h3 className="text-xl dark:text-white font-semibold leading-5 text-gray-800">
            Customer
          </h3>
          <div className="flex flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0">
            <div className="flex flex-col justify-start items-start flex-shrink-0">
              <div className="flex justify-center w-full md:justify-start items-center space-x-4 py-8 border-b border-gray-200">
                 <img
                  src="https://i.ibb.co/5TSg7f6/Rectangle-18.png"
                  alt="avatar"
                  className="h-14 w-14 rounded-full object-cover object-center"
                /> 
             
                {/* <img className="h-14 w-14 rounded-full object-cover object-center"
                 src={
                  single_order?.user?.photo
                            ? `data:image/jpeg;base64,${convertBase64(
                              single_order?.user.photo?.data?.data
                              )}`
                            : imgDefault
                        }
                        alt="user-profile"
                      /> */}

                <div className="flex justify-start items-start flex-col space-y-2">
                  <p className="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">
                    {single_order?.user?.name} 
                  </p>
                </div>
              </div>

              <div className="flex justify-center text-gray-800 dark:text-white md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">
                <img
                  className="dark:hidden"
                  src="https://tuk-cdn.s3.amazonaws.com/can-uploader/order-summary-3-svg1.svg"
                  alt="email"
                />
                <img
                  className="hidden dark:block"
                  src="https://tuk-cdn.s3.amazonaws.com/can-uploader/order-summary-3-svg1dark.svg"
                  alt="email"
                />
                <p className="cursor-pointer text-sm leading-5 ">
                  {single_order?.user?.email}
                </p>
              </div>
            </div>
            <div className="w-full mt-6 md:mt-0">
              <div className="flex justify-center md:justify-start xl:flex-col flex-col md:space-x-6 lg:space-x-8 xl:space-x-0 space-y-4 xl:space-y-12 md:space-y-0 md:flex-row items-center md:items-start">
                <div className="flex justify-center md:justify-start items-center md:items-start flex-col space-y-4 xl:mt-8">
                  <p className="text-base dark:text-white font-semibold leading-4 text-center md:text-left text-gray-800">
                    Shipping Address
                  </p>
                  <p className="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                    {single_order?.address}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleOrder;
