import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Admin from "./components/Dashboard/layouts/Admin";
import User from "./components/Dashboard/layouts/User";
import Sidebar from "./components/Sidebar";
import AboutPage from "./pages/AboutPage";
import CartPage from "./pages/CartPage";
import CheckoutPage from "./pages/CheckoutPage";
import ContactUs from "./pages/ContactUs";
import ErrorPage from "./pages/ErrorPage";
import FavoritePage from "./pages/FavoritePage";
import ForgotPassword from "./pages/ForgotPassword";
import HomePage from "./pages/HomePage";
import ProductsPage from "./pages/ProductsPage";
import ProtectedAdminRoute from "./pages/ProtectedAdminRoute";
import ProtectedUserRoute from "./pages/ProtectedUserRoute";
import ResetPassword from "./pages/ResetPassword";
import SignIn from "./pages/SignIn";
import SingleProductPage from "./pages/SingleProductPage";
import './App.css'



function App() {

  return (
    <Router>
      <Sidebar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/products/:id" element={<SingleProductPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/favorite" element={<FavoritePage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/passwordreset/:resettoken" element={<ResetPassword />} />
        <Route
          path="/admin/*"
          element={
            <ProtectedAdminRoute>
              <Admin />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/user/*"
          element={
            <ProtectedUserRoute>
              <User />
            </ProtectedUserRoute>
          }
        />
        <Route path="*" element={<ErrorPage />} />


        
      </Routes>
    </Router>
  );
}

export default App;
