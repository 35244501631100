import React, { useEffect } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useProductsContext } from "../context/products_context";
import { convertBase64 } from "../utils/helpers";
import { Link } from "react-router-dom";

export default function SingleProductSlider() {
  const { products } = useProductsContext();

  
  return (
    <div className="2xl:container 2xl:mx-auto">
        <div className="lg:px-20 md:px-6 px-4 md:py-12 py-8">
            <div>
                <h1 className="text-3xl lg:text-4xl font-semibold text-gray-800 text-center">Top Selling</h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8 mt-8 md:mt-10">

              {products.filter(product=>product.price>32).map((product,index)=>{
                return(

                <div key={index} className="bg-gray-50 p-8">
                    <div className="">
                        <h2 className="text-xl text-gray-600">{product.name}</h2>
                        <p className="text-xl font-semibold text-gray-800 mt-2">{product.price}$</p>
                    </div>
                    <div className="flex justify-center items-center mt-8 md:mt-24">
                        <img className="" 
                        src={`${product.productPictures[0].img}`}
                        alt="A chair with designed back" />
                    </div>
                          <Link
                            to={`/products/${product?._id}`}
                            className="leading-4 mx-12 2xl:mx-16 text-base lg:leading-5 bg-black rounded-xl py-2 px-20 text-white font-medium"
                            >
                             Shop
                            </Link>
                    <div className="flex justify-end items-center space-x-2 mt-16 md:mt-32">
                        <button aria-label="show in red color" className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" strokeWidth="0.5" />
                            </svg>
                        </button>
                        <button aria-label="show in white color" className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" strokeWidth="0.5" />
                            </svg>
                        </button>
                        <button aria-label="show in black color" className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" strokeWidth="0.5" />
                            </svg>
                        </button>
                    </div>
                </div>
                )
              })}       
            </div>
            
           {products.filter(product=>product.price>50).map((product,index)=>{
            return (
            <div key={index} className="grid grid-cols-1 lg:grid-cols-2 gap-5 md:gap-6 lg:gap-8 mt-4 md:mt-6 lg:mt-8">
                <div className="bg-gray-50 p-8">
                    <div>
                        <h2 className="text-xl leading-tight text-gray-600">{product.name}</h2>
                        <p className="text-xl font-semibold text-gray-800 mt-2">{product.price}</p>
                    </div>
                    <div className="flex justify-center items-center mt-28 md:mt-3">
                        <img src={`${product.productPictures[0].img}`} alt="A large sectional sofa" />
                    </div>
                         <Link
                            to={`/products/${product?._id}`}
                            className="leading-4 mx-12 2xl:mx-16 text-base lg:leading-5 bg-black rounded-xl py-2 px-20 text-white font-medium"
                            >
                             Shop
                            </Link>
                    <div className="flex justify-end items-center space-x-2 mt-36 md:mt-12">
                        <button aria-label="show in yellow color" className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5" cy="5" r="4.75" fill="#F59E0B" stroke="#6B7280" strokeWidth="0.5" />
                            </svg>
                        </button>
                        <button aria-label="show in light gray color" className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5" cy="5" r="4.75" fill="#9CA3AF" stroke="#6B7280" strokeWidth="0.5" />
                            </svg>
                        </button>
                    </div>
                </div>
               
            </div>
            )
           })}
        </div>
    </div>
);



 
  // return (
  //   <div className="container mx-auto">
  //     <h1 className="text-center text-2xl font-semibold mb-2 mt-24 md:mb-8 md:mt-24">
  //       CHECK OUR BEST SELLER
  //     </h1>
  //     <div className="flex items-center justify-center w-full h-full py-8 sm:py-8 px-4">
  //       {/* Carousel for desktop and large size devices */}
  //       <CarouselProvider
  //         className="lg:block hidden"
  //         naturalSlideWidth={100}
  //         isIntrinsicHeight={true}
  //         totalSlides={10}
  //         visibleSlides={4}
  //         step={1}
  //         isPlaying={true}
  //         infinite={true}
  //         interval={3000}
  //       >
  //         <div className="w-full relative flex items-center justify-center">
  //           <ButtonBack
  //             role="button"
  //             aria-label="slide backward"
  //             className="absolute z-30 left-0 ml-8 cursor-pointer"
  //             id="prev"
  //           >
  //             <svg
  //               width={8}
  //               height={14}
  //               viewBox="0 0 8 14"
  //               fill="none"
  //               xmlns="http://www.w3.org/2000/svg"
  //             >
  //               <path
  //                 d="M7 1L1 7L7 13"
  //                 stroke="#A5874A"
  //                 strokeWidth={2}
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //               />
  //             </svg>
  //           </ButtonBack>
  //           <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
  //             <Slider>
  //               <div
  //                 id="slider"
  //                 className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700"
  //               >
  //                 {products?.slice(0, 10).map((product, index) => {
  //                   return (
  //                     <Slide index={index} key={index}>
  //                       <div className="flex flex-shrink-0 relative w-full sm:w-auto cursor-pointer">
  //                         <img
  //                            src={`${product.productPictures[0].img}`}
  //                           alt={product.name}
  //                           className="object-cover object-center w-full"
  //                         />

  //                         <div className="bg-gray-600 bg-opacity-30 absolute w-full h-full p-6">
  //                           <Link
  //                             to={`/products/${product?._id}`}
  //                             className="leading-4 text-base lg:leading-5 bg-black rounded-full py-1 px-2 text-white font-semibold"
  //                           >
  //                             Shop
  //                           </Link>
  //                         </div>
  //                       </div>
  //                     </Slide>
  //                   );
  //                 })}
  //               </div>
  //             </Slider>
  //           </div>
  //           <ButtonNext
  //             role="button"
  //             aria-label="slide forward"
  //             className="absolute z-30 right-0 mr-8"
  //             id="next"
  //           >
  //             <svg
  //               width={8}
  //               height={14}
  //               viewBox="0 0 8 14"
  //               fill="none"
  //               xmlns="http://www.w3.org/2000/svg"
  //             >
  //               <path
  //                 d="M1 1L7 7L1 13"
  //                 stroke="#A5874A"
  //                 strokeWidth={2}
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //               />
  //             </svg>
  //           </ButtonNext>
  //         </div>
  //       </CarouselProvider>

  //       {/* Carousel for mobile and Small size Devices */}
  //       <CarouselProvider
  //         className="block md:hidden "
  //         naturalSlideWidth={100}
  //         isIntrinsicHeight={true}
  //         totalSlides={10}
  //         visibleSlides={1}
  //         step={1}
  //         infinite={true}
  //         isPlaying={true}
  //         interval={3000}
  //       >
  //         <div className="w-full relative flex items-center justify-center">
  //           <ButtonBack
  //             role="button"
  //             aria-label="slide backward"
  //             className="absolute z-30 left-0 ml-8 cursor-pointer"
  //             id="prev"
  //           >
  //             <svg
  //               width={8}
  //               height={14}
  //               viewBox="0 0 8 14"
  //               fill="none"
  //               xmlns="http://www.w3.org/2000/svg"
  //             >
  //               <path
  //                 d="M7 1L1 7L7 13"
  //                 stroke="#A5874A"
  //                 strokeWidth={2}
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //               />
  //             </svg>
  //           </ButtonBack>
  //           <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
  //             <Slider>
  //               <div
  //                 id="slider"
  //                 className="h-full w-full flex lg:gap-8 md:gap-6 items-center justify-start transition ease-out duration-700"
  //               >
  //                 {products?.slice(0, 10).map((product, index) => {
  //                   return (
  //                     <Slide index={index} key={index}>
  //                       <div className="flex flex-shrink-0 relative w-full sm:w-auto cursor-pointer">
  //                         <img
  //                           // src={`data:image/jpeg;base64,${convertBase64(
  //                           //   product?.photo.data?.data
  //                           // )}`}
  //                           src={`${product.productPictures[0].img}`}
  //                           alt={product.name}
  //                           className="object-cover object-center w-full"
  //                         />
  //                         <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
  //                           <Link
  //                             to={`/products/${product?._id}`}
  //                             className="leading-4 text-base lg:leading-5 bg-black rounded-full py-1 px-2 text-white font-semibold"
  //                           >
  //                             Shop
  //                           </Link>
  //                         </div>
  //                       </div>
  //                     </Slide>
  //                   );
  //                 })}
  //               </div>
  //             </Slider>
  //           </div>
  //           <ButtonNext
  //             role="button"
  //             aria-label="slide forward"
  //             className="absolute z-30 right-0 mr-8"
  //             id="next"
  //           >
  //             <svg
  //               width={8}
  //               height={14}
  //               viewBox="0 0 8 14"
  //               fill="none"
  //               xmlns="http://www.w3.org/2000/svg"
  //             >
  //               <path
  //                 d="M1 1L7 7L1 13"
  //                 stroke="#A5874A"
  //                 strokeWidth={2}
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //               />
  //             </svg>
  //           </ButtonNext>
  //         </div>
  //       </CarouselProvider>
  //     </div>
  //   </div>
  // );
}
