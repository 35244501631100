import React from 'react'
import { useCategoriesContext } from '../../../context/categories_context'

const AlertCategory = () => {
    const {alertType,alertText}=useCategoriesContext();
    return (
        <div className={`py-2 px-3 mb-4 border-transparent rounded-md text-center tracking-widest ${alertType === 'danger' ? 'text-red-600 bg-[#F8D7DA]' : 'text-green-800 bg-green-300'}`}>
            {alertText}
        </div>
    )
}

export default AlertCategory