import React from "react";
import CardAddCategory from "../../Cards/CardAddCategory";
import CardAddSubCategory from "../../Cards/CardAddSubCategory";

export default function Categories() {
  return (
    <>
      <div className="flex flex-col items-center py-32">
        <div className="w-4/5 lg:w-2/5">
          <CardAddCategory />
        </div>
        <div>
          <CardAddSubCategory />
        </div>
      </div>
    </>
  );
}
