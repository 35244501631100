import React, { useEffect, useState } from 'react'
import { IoBagHandle, IoPieChart, IoPeople, IoCart } from 'react-icons/io5'
import { useProductsContext } from "../../../../context/products_context";
import { useUsersContext } from "../../../../context/users_context";




export const StartDashboard = () => {
	const { getAllOrders, orders } = useProductsContext();
	const { user, token, getAllUsers, users } = useUsersContext();
	const {getAllProducts} = useProductsContext()
  
	useEffect(() => {
	  getAllOrders(user._id, token);
	}, [getAllOrders, token, user._id]);
  
	useEffect(() => {
	  getAllUsers(user._id);
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	
	}, []);
	useEffect(() => {
		getAllProducts()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	 
	  }, []);
	let [total] =useState()
	
  return (
    <div className="flex pl-5 pt-10 gap-10 ">

    {/* {  total = orders.reduce((accum,item) => accum + item.amount, 0)} */}
		
			<BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-black">
					<IoBagHandle className="text-2xl text-white" />
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Total Sales</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">{ total = orders?.reduce((accum,item) => accum + item.amount, 0)}$</strong>
						<span className="text-sm text-green-500 pl-2">+343</span>
					</div>
				</div>
			</BoxWrapper>
			<BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-black">
					<IoPieChart className="text-2xl text-white" />
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Total Expenses</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">$3423</strong>
						<span className="text-sm text-green-500 pl-2">-343</span>
					</div>
				</div>
			</BoxWrapper>
			<BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-black">
					<IoPeople className="text-2xl text-white" />
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Total Customers</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">{users?.length}</strong>
						<span className="text-sm text-red-500 pl-2">-30</span>
					</div>
				</div>
			</BoxWrapper>
			<BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-black">
					<IoCart className="text-2xl text-white" />
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Total Orders</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">{orders?.length}</strong>
						<span className="text-sm text-red-500 pl-2">-43</span>
					</div>
				</div>
			</BoxWrapper>
		</div>
	)
}



function BoxWrapper({ children }) {
	return <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center">{children}</div>
}

