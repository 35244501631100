import React, { useEffect } from "react";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { FiSettings } from "react-icons/fi";
import { Route, Routes } from "react-router-dom";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import Ecommerce from "../views/admin/Ecommerce";
import OrdersNew from "../views/admin/OrdersNew";
import UsersNew from "../views/admin/UsersNew";
import { useStateContext } from "../../../context/ContextProvider";
import Categories from "../views/admin/Categories";
import AddProduct from "../views/admin/AddProduct";
import Orders from "../views/admin/Orders";
import Users from "../views/admin/Users";
import Account from "../views/admin/Account";
import Security from "../views/admin/Security";
import CardUserSingleOrder from "../Cards/CardUserSingleOrder";
import SelectStatus from "../Cards/SelectStatus";
import SingleOrder from "../views/admin/SingleOrder";
import Overview from "../views/admin/Overview";


export default function Admin() {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);
  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <div className="flex relative dark:bg-main-dark-bg">
        <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
          <TooltipComponent content="Settings" position="TopCenter">
            <button
              type="button"
              onClick={() => setThemeSettings(true)}
              className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white"
              style={{ background: currentColor, borderRadius: "50%" }}
            >
              <FiSettings />
            </button>
          </TooltipComponent>
        </div>
        {activeMenu ? (
          <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
            <Sidebar />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg">
            <Sidebar />
          </div>
        )}
        <div
          className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${
            activeMenu ? "md:ml-72" : "flex-2"
          }`}
        >
          <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
            <Navbar />
          </div>

          <div>
            <Routes>
              {/* Dashboard */}
              <Route path="/" element={<Ecommerce />} />
              <Route path="/ecommerce" element={<Ecommerce />} />
              
              {/* Pages */}
               <Route path="/orders" element={<Orders />} /> 
              <Route path="/customers" element={<Users />} /> 
              <Route path="/categories" element={<Categories />} />
              <Route path="/products" element={<AddProduct />} />
              <Route path="/account" element={<Account />} />
              <Route path="/security" element={<Security />} />
              <Route path="/overview" element={<Overview />} />
              {/* <Route path="/editeproduct/:id" element={<EditProduct/>} />
               */}
              {/*added routes*/}
              <Route path="orders/:idOrder" element={<SingleOrder/>} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}
