import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import Navbar from "../components/Navbar";
import ShowPassword from "../components/ShowPassword";
import { useGlobalContext } from "../context/global_context";
import { useUsersContext } from "../context/users_context";

const initialState = {
  name: "",
  email: "",
  password: "",
  isMember: true,
};

const SignIn = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const { token, user, showAlert, displayAlert, registerUser, loginUser } =
    useUsersContext();
  const { closeSubmenu} = useGlobalContext();

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const toggleMember = () => {
    setValues({ ...values, isMember: !values.isMember });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, password, isMember } = values;
    if (!email || !password || (!isMember && !name)) {
      displayAlert("please provide all values");
      return;
    }
    const currentUser = { email, password };
    const registredUser = { name, email, password };
    if (isMember) {
      loginUser(currentUser);
    } else {
      registerUser(registredUser);
    }
  };
  useEffect(() => {
    if (token && user) {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  }, [token, user, navigate]);
  return (
    <div className="bg-white  md:bg-gradient-to-l md:from-black md:to-black  ">
      <Navbar/>
      <div
        onMouseOver={closeSubmenu}
        className="grid grid-cols-1 md:grid-cols-[500px_1fr] py-10 "
      >
        <div className="py-10">
          <div className="rounded-lg my-8 py-3 px-8 ">
            <p className="focus:outline-none text-3xl font-extrabold leading-6 md:text-white">
              {values.isMember
                ? "Sign in to your account"
                : "Create a new account"}
            </p>
            {values.isMember && (
              <div>
                <button
                  aria-label="Continue with google"
                  className="focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded-lg border-gray-700 flex items-center w-full mt-10"
                >
                  <img
                    src="https://tuk-cdn.s3.amazonaws.com/can-uploader/sign_in-svg2.svg"
                    alt="google"
                  />
                  <p className="text-base font-medium ml-4 md:text-white">
                    Continue with Google
                  </p>
                </button>

                <h2 className="w-full text-center border-b-2 border-solid border-gray-300 leading-[0.1rem] mt-8  ">
                  <span className="bg-white border-0 py-0 px-2 text-gray-400 font-medium">
                    OR
                  </span>
                </h2>
              </div>
            )}

            <form onSubmit={handleSubmit} className="mt-6 space-y-6 ">
              {showAlert && <Alert />}
              {!values.isMember && (
                <div>
                  <label
                    htmlFor=""
                    className="block text-lg font-medium md:text-white"
                  >
                    Name
                  </label>
                  <div className="mt-3">
                    <input
                      type="text"
                      className="w-full border-gray-300 rounded-lg shadow-sm focus:border-black focus:ring-black"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}
              <div>
                <label
                  htmlFor=""
                  className="block text-lg font-medium md:text-white"
                >
                  Email address
                </label>
                <div className="mt-3">
                  <input
                    type="email"
                    className="w-full border-gray-300 rounded-lg shadow-sm focus:border-black focus:ring-black"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor=""
                  className="block text-lg font-medium md:text-white"
                >
                  Password
                </label>
                <div className="mt-3 flex items-center">
                  <input
                    type={passwordShown ? "text" : "password"}
                    id="new-password"
                    className="w-full border-gray-300 rounded-lg shadow-sm focus:border-black focus:ring-black"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  <ShowPassword
                    togglePassword={togglePassword}
                    passwordShown={passwordShown}
                  />
                </div>
              </div>
              {values.isMember && (
                <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="rounded-sm shadow-sm text-gray-800"
                    />
                    <label htmlFor="" className="md:text-white font-medium">
                      Remember me
                    </label>
                  </div>
                  <div>
                    <Link
                      to="/forgotpassword"
                      className="md:text-white font-medium cursor-pointer hover:underline"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </div>
              )}
              <div>
                <button
                  type="submit"
                  className="font-medium w-full  mt-3 py-2 px-4 border border-transparent rounded-md shadow-sm text-lg text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 transition-all duration-500 ease-out "
                >
                  {values.isMember ? "Sign in" : "Register"}
                </button>
              </div>
            </form>
            <div className="my-3 flex justify-center ">
              {values.isMember ? (
                <p className="md:text-white font-medium">
                  Not a member yet?
                  <span
                    className="text-gray-400 ml-2 cursor-pointer hover:underline"
                    onClick={toggleMember}
                  >
                    Register
                  </span>
                </p>
              ) : (
                <p className="text-gray-700 font-medium mx-2">
                  already a member?
                  <span
                    className="text-gray-300 ml-2 cursor-pointer hover:underline "
                    onClick={toggleMember}
                  >
                    Sign in
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="hidden md:block bg-login bg-no-repeat bg-cover bg-center bg-blend-darken"></div>
      </div>
    </div>
  );
};

export default SignIn;
