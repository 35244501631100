import React from 'react'
import { Navigate } from 'react-router-dom';
import { useUsersContext } from '../context/users_context'
import { parseJwt } from '../utils/helpers'

const ProtectedUserRoute = ({children}) => {
    const {token}  = useUsersContext();
    const userInfo = parseJwt(token);
    if(!token){
        return <Navigate to='/' />
    }
    
      if(userInfo.role === 1) {
          return <Navigate to='/admin/dashboard' />
      }
   
  return children
}

export default ProtectedUserRoute