 import React, { useEffect, useState } from "react";
 import { useDropzone } from "react-dropzone";
 import { useCategoriesContext } from "../../../context/categories_context";
 import { useUsersContext } from "../../../context/users_context";
 import { useProductsContext } from "../../../context/products_context";
 import { useLocation, useNavigate } from "react-router-dom";
 const productInfo = {
   productPictures: [],
   name: "",
   price: 0,
   quantity: 0,
   category: "",
   description: "",
 };
 const CardAddProduct = () => {
   const { all_categories } = useCategoriesContext();
   const { user, token } = useUsersContext();
   const { addProduct, editProduct, isEditing } = useProductsContext();
   const { _id } = user;
   const [files, setFiles] = useState([]);
   const [product, setProduct] = useState(productInfo);
   const [formdata] = useState(new FormData());
   const location = useLocation();
   let navigate = useNavigate();
   const [editedProduct, setEditedProduct] = useState(location.state);
   const [pic,setPic]=useState()
   const {
     getRootProps,
     getInputProps,
     isDragActive,
     isFocused,
     isDragAccept,
     isDragReject,
   } = useDropzone({
     accept: "image/*",
     onDrop: (acceptedFiles) => {
       setFiles(
         acceptedFiles.map((file) =>
           Object.assign(file, {
             preview: URL.createObjectURL(file),
           })
         )
       );
     },
   });
   const handlechange = (e) => {
     formdata.set(e.target.id, e.target.value);
     isEditing
       ? setEditedProduct({ ...editedProduct, [e.target.id]: e.target.value })
       : setProduct({ ...product, [e.target.id]: e.target.value });
   }; 
   useEffect(() => {   
    //  formdata.append("productPictures", editedProduct?.productPictures);
     formdata.append("name", editedProduct?.name);
     formdata.append("price", editedProduct?.price);
     formdata.append("quantity", editedProduct?.quantity);
     formdata.append("category", editedProduct?.category);
     formdata.append("description", editedProduct?.description);
        // setProduct({ ...productInfo, productPictures });     
     // eslint-disable-next-line react-hooks/exhaustive-deps
           for(let i=0;i<files.length;i++){
             formdata.append("productPicture",files[i])
           }

   }, [files]);
      // for (let pair of formdata.entries()) {
      //   console.log(pair[0]+ ' - ' + pair[1]); 
      // } 
   return (
     <div className="lg:-mt-20">
       <h1 className="text-gray-600 tracking-wider text-xl font-bold mb-5">
         Add Product
       </h1>
       <div className="shadow-lg rounded-lg bg-white px-5 py-8">
         <form
           onSubmit={(e) => {
             e.preventDefault();
             isEditing
               ? editProduct(_id, token, editedProduct?._id, formdata)
               : addProduct(_id, token, formdata);
             navigate("/products", { replace: true });
           }}
           className="space-y-5"
         >             
           <h1 className="text-gray-500 font-semibold mb-5">Image</h1>
           <div {...getRootProps()}>
           {/* {...getInputProps()} */}
             {/* <input type="photo" value={isEditing ? editedProduct?.photo :files[0]}  onChange={handlechange}
                 id="photo"/> */}                
             <input {...getInputProps()}/>
             <div className="flex items-center justify-center w-full">
               <label
                 className={`flex flex-col w-full h-40 shadow-lg  rounded-lg hover:bg-gray-100 hover:border-gray-300 ${
                   isFocused && "bg-gray-100 border-gray-300"
                 } ${isDragAccept && "bg-gray-100 border-2 border-green-600"} ${
                   isDragReject && "bg-gray-100 border-2 border-red-600"
                 } `}
               >
                 <div className="flex flex-col items-center justify-center pt-5">
                   <svg
                     xmlns="http://www.w3.org/2000/svg"
                     className={`w-8 h-8 text-gray-400 group-hover:text-gray-600 ${
                       isDragReject && "text-red-400"
                     } ${isDragAccept && "text-green-400"}`}
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor"
                   >
                     <path
                       strokeLinecap="round"
                       strokeLinejoin="round"
                       strokeWidth="2"
                       d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                     />
                   </svg>
                   {isDragActive ? (
                     <p className="text-gray-500 font-semibold my-2">
                       {isDragReject
                         ? "Only images are accepted !"
                         : "drop your image here ..."}
                     </p>
                   ) : (
                     <p className="text-gray-500 text-center font-semibold my-2">
                       Drag & drop your image here{" "}
                       <span className="block mb-3">Or</span>
                       <span className="shadow-lg cursor-pointer bg-gray-100 hover:bg-white text-[#B5942F] font-medium rounded-lg px-4 border py-2 border-[#d1b152]">
                         Browse images
                       </span>
                     </p>
                   )}
                 </div>
               </label>
             </div>
           </div>
           {files.length > 0 && (          
             <h1 className="text-gray-500 font-semibold mb-5">Uploaded Image</h1>
           )}





           {files.map((item) => {          
             return (
               <div
                 key={item.name}
                 className="shadow-lg rounded-lg flex gap-2 bg-white"
               >
                 <div className="w-32">
                   <img src={item.preview} alt="" className="object-cover" />
                 </div>
                 <div>
                   <h1 className="text-xl text-gray-500 font-medium">
                     {item.name}
                   </h1>
                   <h1 className="text-gray-400 font-medium">
                     {(item.size / 1000000).toFixed(2)} Mo
                   </h1>
                 </div>
               </div>
             );
           })}

           
            {/* {isEditing && editedProduct.productPictures.map((pic) => {          
             return (
               <div
                 key={pic._id}
                 className="shadow-lg rounded-lg flex gap-2 bg-white"
               >
                 <div className="w-32">
                   <img src={pic.img} alt="" className="object-cover" />
                 </div>
                 <div>
                 </div>
               </div>
             );
            })} */}
         
            {/* {isEditing && files.map((item)=>{
              return(
             <>  
             <h1 className="text-xl text-gray-500 font-medium">
               {item.name}
             </h1> 
              <h1 className="text-gray-400 font-medium">
               {(item.size / 1000000).toFixed(2)} Mo
             </h1>
             </>
              )
            })} */}

           <h1 className="text-gray-500 font-semibold">General</h1>
           <div className="grid md:grid-cols-[1fr_1fr] gap-5">
             <div className="space-y-2">
               <label
                 htmlFor="title"
                 className="block text-lg font-medium text-gray-700"
               >
                 Title
               </label>
               <input
                 type="text"
                 value={isEditing ? editedProduct?.name : product.name}
                 onChange={handlechange}
                 id="name"
                 className="w-full border-gray-300 rounded-lg shadow-sm focus:border-[#B5942F] focus:ring-[#B5942F]"
               />            
             </div>
             <div className="space-y-2">
               <label
                 htmlFor="price"
                 className="block text-lg font-medium text-gray-700"
               >
                 Price
               </label>
               <input
                 id="price"
                 type="number"
                 value={isEditing ? editedProduct?.price : product.price}
                 onChange={handlechange}
                 className="w-full border-gray-300 rounded-lg shadow-sm focus:border-[#B5942F] focus:ring-[#B5942F]"
               />
             </div>
             <div className="space-y-2">
               <label
                 htmlFor="quantity"
                 className="block text-lg font-medium text-gray-700"
               >
                 Quantity
               </label>
               <input
                 type="number"
                 id="quantity"
                 value={isEditing ? editedProduct?.quantity : product.quantity}
                 onChange={handlechange}
                 className="w-full border-gray-300 rounded-lg shadow-sm focus:border-[#B5942F] focus:ring-[#B5942F]"
               />
             </div>
             <div className="space-y-2">
               <label
                 htmlFor="quantity"
                 className="block text-lg font-medium text-gray-700"
               >
                 Category
               </label>
               <select
                 name=""
                 id="category"
                 value={isEditing ? editedProduct?.category : product.category}
                 onChange={handlechange}
                 className="w-full border-gray-300 rounded-lg shadow-sm focus:border-[#B5942F] focus:ring-[#B5942F]"
               >
                 <option value="" disabled>
                   Select a category name
                 </option>
                 {all_categories?.map((item, index) => {
                   return (
                     <option key={index} value={item._id}>
                       {item.name}
                     </option>
                   );
                 })}
               </select>
             </div>
             <div className="space-y-2">
               <label
                 htmlFor="quantity"
                 className="block text-lg font-medium text-gray-700"
               >
                 subcatego
               </label>
               <select
                 name=""
                 id="category"
                 value={isEditing ? editedProduct?.category : product.category}
                 onChange={handlechange}
                 className="w-full border-gray-300 rounded-lg shadow-sm focus:border-[#B5942F] focus:ring-[#B5942F]"
               >
                 <option value="" disabled>
                   Select a category name
                 </option>
                 {all_categories?.map((item, index) => {
                   return (
                     <option key={index} value={item._id}>
                       {item.name}
                     </option>
                   );
                 })}
               </select>
             </div>
           </div>
           <div className="space-y-2">
             <label
               htmlFor="description"
               className="block text-lg font-medium text-gray-700"
             >
               Description
             </label>
             <textarea
               name="description"
               id="description"
               cols="30"
               rows="10"
               value={
                 isEditing ? editedProduct?.description : product.description
               }
               onChange={handlechange}
               className="w-full border-gray-300 rounded-lg shadow-sm focus:border-[#B5942F] focus:ring-[#B5942F]"
             ></textarea>
           </div>
           {/* <input type="file" id="photo" onChange={handlechange} /> */}
           <div className="flex justify-center lg:justify-end">
             <button
               className="bg-[#B5942F] mt-4 tracking-widest text-white active:bg-[#a3852a] font-bold uppercase text-sm px-5 py-3 rounded-lg shadow hover:shadow-md hover:bg-[#a3852a] outline-none focus:outline-none ease-linear transition-all duration-150"
               type="submit"
             >
               {isEditing ? "Edit Product" : "Add Product"}
             </button>
           </div>
         </form>
       </div>
     </div>
   );
 };
 export default CardAddProduct;



/////////solution///////////////////

//  import axios from "axios";
//  import { Formik } from "formik";
//  import React, { useState } from "react";
//  import { useEffect } from "react";
//  import toast from "react-hot-toast";
//  import { Navigate } from "react-router-dom";
//  import { useCategoriesContext } from "../../../context/categories_context";
//  import { useProductsContext } from "../../../context/products_context";
//  import { useUsersContext } from "../../../context/users_context";
//  const CardAddProduct = () => {
//    const { user, token } = useUsersContext();
//    const [pic, setPic] = useState();
//    const { addProduct } = useProductsContext();
//    const { all_categories } = useCategoriesContext();
//    const { _id } = user;
//    return (
//      <div className="py-10">
//        <h1 className="border-b text-lg pb-2 uppercase font-semibold border-gray-300">
//          Add Product
//        </h1>
//        <div className="bg-white border border-gray-300 px-5 py-5 mt-5">
//          <Formik
//            initialValues={{
//              name: "",
//              price: "",
//              category: "",
//              description: "",
//            }}
//            onSubmit={(values, { setSubmitting }) => {
//              const data = new FormData();
//              data.append("name", values.name);
//              data.append("price", values.price);
//              data.append("category", values.category);
//              data.append("description", values.description);
//              for (let i = 0; i < pic.length; i++) {
//                data.append("productPicture", pic[i]);
//              }
//              addProduct(_id, token, data);

//               for (let pair of data.entries()) {
//                 console.log(pair[0]+ ' - ' + pair[1]); 
//               }
//              setSubmitting(false);
//            }}
//          >
//            {({ values, handleChange, handleSubmit, isSubmitting }) => (
//              <form action="" onSubmit={handleSubmit} className="space-y-5">
//                <input
//                  type="file"
//                  multiple
//                  onChange={(e) => setPic(e.target.files)}
//                />
//                <div className="space-y-2">
//                  <label
//                    htmlFor="name"
//                    className="block text-lg font-medium text-gray-700"
//                  >
//                    Name
//                  </label>
//                  <input
//                    type="text"
//                    id="name"
//                    value={values.name}
//                    onChange={handleChange}
//                    className="w-full border-gray-300 rounded-lg shadow-sm focus:border-cyan-400 focus:ring-cyan-400"
//                  />
//                </div>
//                <div className="flex items-center w-full gap-4">
//                  <div className="space-y-2 w-full">
//                    <label
//                      htmlFor="price"
//                      className="block text-lg font-medium text-gray-700"
//                    >
//                      Price
//                    </label>
//                    <input
//                      id="price"
//                      type="number"
//                      value={values.price}
//                      onChange={handleChange}
//                      className="w-full border-gray-300 rounded-lg shadow-sm focus:border-cyan-400 focus:ring-cyan-400"
//                    />
//                  </div>
//                  <div className="space-y-2 w-full">
//                    <label
//                      htmlFor="quantity"
//                      className="block text-lg font-medium text-gray-700"
//                    >
//                      Category
//                    </label>
//                    <select
//                      name=""
//                      id="category"
//                      value={values.category}
//                      onChange={handleChange}
//                      className="w-full border-gray-300 rounded-lg shadow-sm focus:border-cyan-500 focus:ring-cyan-500"
//                    >
//                      <option value="" disabled>
//                        Select a category name
//                      </option>
//                      {all_categories.map((item, index) => {
//                        return (
//                          <option key={index} value={item._id}>
//                            {item.name}
//                          </option>
//                        );
//                      })}
//                    </select>
//                  </div>
//                </div>            
//                <div className="space-y-2">
//                  <label
//                    htmlFor="description"
//                    className="block text-lg font-medium text-gray-700"
//                  >
//                    Description
//                  </label>
//                  <textarea
//                    name="description"
//                    value={values.description}
//                    onChange={handleChange}
//                    id="description"
//                    cols="30"
//                    rows="10"
//                    className="w-full border-gray-300 rounded-lg shadow-sm focus:border-cyan-400 focus:ring-cyan-400"
//                  ></textarea>
//                </div>
//                <div className="flex justify-center lg:justify-end">
//                  <button
//                    disabled={isSubmitting}
//                    className="bg-cyan-500 tracking-widest text-white active:bg-cyan-500 font-bold uppercase text-sm px-5 py-3 rounded-lg shadow hover:shadow-md hover:bg-cyan-600 outline-none focus:outline-none ease-linear transition-all duration-150"
//                    type="submit"
//                  >
//                    {isSubmitting ? "creating..." : "Add Place"}
//                  </button>
//                </div>
//                {/*update add button 25/03/2023*/ }
//                <div className="flex justify-center lg:justify-start">
//                  <button
//                    className="bg-cyan-500 tracking-widest text-white active:bg-cyan-500 font-bold uppercase text-sm px-5 py-3 rounded-lg shadow hover:shadow-md hover:bg-cyan-600 outline-none focus:outline-none ease-linear transition-all duration-150"
//                    type="button"
//                  > Check your Products 
//                    <a  href="/product"></a>
//                  </button>
//                </div>
//              </form>
//            )}
//          </Formik>
//        </div>
//      </div>
//    );
//  };
//  export default CardAddProduct;
