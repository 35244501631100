import React from "react";
import { MdOutlineCancel } from "react-icons/md";
// import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

// import { cartData } from "../../data/dummy";
import { useStateContext } from "../../context/ContextProvider";
import Button from "./Button";
import { useCartContext } from "../../context/cart_context";
import { convertBase64 } from "../../utils/helpers";
// import CartTotals from "./../CartTotals";
import CartTotalsNew from "./CartTotalsNew";
// import AmountButtons from "components/AmountButtons";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LazyLoad from "react-lazy-load";



const Cart = ({ id, photo, name, price, amount }) => {
  const {  toggleAmount } = useCartContext();
  // const { currentColor } = useStateContext();
  const { cart } = useCartContext();

  const increase = () => {
    toggleAmount(id, "inc");
  };
  const decrease = () => {
    toggleAmount(id, "dec");
  };
  const settings = {
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="flex justify-center bg-half-transparent  w-full fixed nav-item top-0 right-0  overflow-y-scroll  -px-20">
      
      <div className="float-right h-screen  duration-1000 ease-in-out dark:text-gray-200 transition-all dark:bg-[#484B52] bg-white md:w-400  ">
        <div className="flex justify-between items-center">
          <p className="font-semibold text-lg ml-8 ">Shopping Cart</p>
          <Button
            icon={<MdOutlineCancel />}
            color="rgb(153, 171, 180)"
            bgHoverColor="light-gray"
            size="2xl"
            borderRadius="50%"
          />
        </div>
        {cart?.map((item, index) => (
          
          <div key={index}>
             
            <div>
              <div className="flex items-center   leading-8 gap-5 border-b-1 border-color dark:border-gray-600 ">

                  {/* {console.log(item.productPictures[0])} */}
                <img className="rounded-lg h-50 w-24"
                 src={item.productPictures[0].img} alt="cart" />
                <div>
                  <p className="font-semibold ">{item.name}</p>
                  <p className="text-gray-600 dark:text-gray-400 text-sm font-semibold">
                    {item.category}
                  </p>
                  <div className="flex gap-4 mt-2 items-center">
                    <p className="font-semibold text-lg">{item.price} $</p>
                    <div className="flex items-center border-1 border-r-0 border-color rounded">
                      <p className="p-2 border-r-1 dark:border-gray-600 border-color text-red-600 ">
                        {/* <AiOutlineMinus /> */}
                      </p>
                      <p className="p-2 border-r-1 border-color dark:border-gray-600 text-green-600">
                      {/* {amount} */}
                      </p>
                      <p className="p-2 border-r-1 border-color dark:border-gray-600 text-green-600">
                        {/* <AiOutlinePlus /> */}
                      </p>
                      {/* <div className="flex justify-center w-1/5">
                   <AmountButtons
                   amount={amount}
                 increase={increase}
               decrease={decrease}
                   />
                      </div> */}


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

             <a
              href="/cart"
              className="inline-block text-sm rounded-xl border border-transparent bg-black ml-8 px-20 py-2 text-center font-medium text-white hover:bg-neutral-700"
            >
              EDIT YOUR CART
            </a>
         {/* <Link
            to="/cart"
            className=" py-4 px-24 ml-4 text-center bg-black font-semibold hover:bg-gray-700    text-sm text-white uppercase w-full"
          >
            Edit Your Cart
          </Link> */}
          
        <div className="mt-3 mb-3 h-screen w-full  bg-white ">
          <div className="flex justify-between items-center ">
            {/* <p className="text-gray-500 dark:text-gray-200">Sub Total</p> */}
            <p className="font-semibold">{cart.price}</p>
          </div>
          <div className="flex justify-between items-center pb-10 ">
               <CartTotalsNew />
            <p className="text-gray-500 dark:text-gray-200"></p>
            <p className="font-semibold">{cart.price}</p>
          </div>
        </div>
        {/* <div className="mt-5">
          <Button
            color="white"
            bgColor={currentColor}
            text="Place Order"
            borderRadius="10px"
            width="full"
          />
        </div> */}
      </div>
    </div>
  );
};

export default Cart;
