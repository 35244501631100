// import React, { useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import { useProductsContext } from "../context/products_context";
// import { convertBase64, formatPrice } from "../utils/helpers";
// import { Link } from "react-router-dom";
// import Loading from "../components/Loading";
// import Error from "../components/Error";
// import PageHero from "../components/PageHero";
// import AddToCart from "../components/AddToCart";
// import Navbar from "../components/Navbar";
// import { useGlobalContext } from "../context/global_context";

// const SingleProductPage = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const {
//     single_product_loading: loading,
//     single_product_error: error,
//     single_product: product,
//     getSingleProduct,
//   } = useProductsContext();
//   const { closeSubmenu } = useGlobalContext();

//   useEffect(() => {
//     getSingleProduct(id);
//     // eslint-disable-next-line
//   }, [id]);

//   useEffect(() => {
//     if (error) {
//       setTimeout(() => {
//         navigate("/products");
//       }, 3000);
//     }
//     // eslint-disable-next-line
//   }, [error]);

//   if (loading) {
//     return <Loading />;
//   }

//   if (error) {
//     return <Error />;
//   }

//   const { name, price, description, quantity, _id: sku, productPictures } = product;
//   return (
//     <>
//       <Navbar bg="bg-black" />
//       <div onMouseOver={closeSubmenu}>
//         <PageHero title={name} product />
//         <div className="pb-5 px-0 w-[90vw] max-w-[1170px] my-0 mx-auto">
//           <Link
//             to="/products"
//             className="inline-block bg-gray-600 font-medium text-sm cursor-pointer shadow-md rounded-md tracking-wider py-2 px-3 text-white hover:bg-gray-700 transition-all duration-500 ease-linear"
//           >
//             back to products
//           </Link>
//           <div className="grid gap-16 mt-8 md:grid-cols-[1fr_1fr] lg:items-center">
//             <div className="shadow-lg border rounded-lg border-gray-100">
//                <img
//                 className="w-[90vw] h-full rounded-md md:h-[600px] lg:h-[500px] block object-contain"
//                 // src={`data:image/jpeg;base64,${convertBase64(
//                 //   photo.data.data
//                 // )}`} 
//                 src={`${productPictures[0].img}`}
//                 alt=""
//               />
//             </div>
//             <section className="content">
//               <h2 className="text-[#102A42] font-bold text-4xl">{name}</h2>
//               <h5 className="font-bold mt-3 mb-5 text-xl">
//                 {formatPrice(price)}
//               </h5>
//               <p className="leading-loose md:min-w-[45em] mb-5">
//                 {description}
//               </p>
//               <p className="capitalize text-gray-500 font-medium w-[300px] grid grid-cols-[125px_1fr]">
//                 <span className="text-[#102A42] font-medium">Available : </span>
//                 {quantity > 0 ? "In stock" : "out of stock"}
//               </p>
//               <p className="capitalize text-gray-500 font-medium my-4 w-[300px] grid grid-cols-[125px_1fr]">
//                 <span className="text-[#102A42] font-medium">SKU : </span>
//                 {sku}
//               </p>

//               <hr />
//               {quantity > 0 && <AddToCart product={product} />}
//             </section>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SingleProductPage;

import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useProductsContext } from "../context/products_context";
import { convertBase64, formatPrice } from "../utils/helpers";
// import { Link } from "react-router-dom";
// import Loading from "../components/Loading";
// import Error from "../components/Error";
import PageHero from "../components/PageHero";
import Navbar from "../components/Navbar";
import { Dialog } from "@headlessui/react";
import { useState } from "react";
// import { FaAirbnb, FaTripadvisor } from "react-icons/fa";
 import { AiOutlineAppstore } from "react-icons/ai";
//  import { TbBrandBooking } from "react-icons/tb";
import LazyLoad from "react-lazy-load";
// import SimilarRiads from "../components/SimilarRiads";
// import Reviews from "../components/Reviews";
import Slider from "react-slick";
// import { imgUrl } from "../utils/constants";
import { useCartContext } from "../context/cart_context";
import AddToFavorite from "../components/AddToFavorite";
import Footer from "../components/Footer";
import SimilarProducts from "../components/Dashboard/SimilarProducts";


const settings = {
  dotsClass: "slick-dots slick-thumb",
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const SingleProductPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const {
    single_product_loading: loading,
    single_product_error: error,
    single_product: product,
    getSingleProduct,
  } = useProductsContext();

  const { addToFavorite } = useCartContext();
  const { addToCart } = useCartContext();
  useEffect(() => {
    getSingleProduct(id);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        navigate("/products");
      }, 3000);
    }
    // eslint-disable-next-line
  }, [error]);

  // if (loading) {
  //   return <Loading />;
  // }

  // if (error) {
  //   return <Error />;
  // }

  const {
    _id,
    name,
    price,
    description,
    category,
    productPictures,
    reviews,

  } = product;

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 overflow-y-auto">
          {/* Container to center the panel */}
          <div className="flex min-h-full items-center justify-center p-4">
            {/* The actual dialog panel  */}
            <Dialog.Panel className="mx-auto  max-w-7xl overflow-hidden z-[1000] rounded bg-white">
              <Dialog.Title className="text-xl font-semibold py-4 text-center">
                All Pictures
              </Dialog.Title>
              <div className="grid grid-cols-[1fr,1fr] gap-2">
                {productPictures?.map((p, index) => {
                  return (
                    <LazyLoad key={index}>
                      <img
                        className="object-cover h-full w-full object-center"
                        // src={`${imgUrl}/uploads/${p.img}`}
                        src={`${p.img}`}
                        alt=""
                      />
                    </LazyLoad>
                  );
                })}
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
      <Navbar bg="bg-gradient-to-l from-gray-800 to-black"/>
      <div>
        <PageHero title={name} product />
        <div className="pb-5 px-0 w-[90vw] max-w-[1170px] my-0 mx-auto">
          <div className="mb-5 flex justify-between items-center">
            <div>
              <h1 className="capitalize font-semibold text-3xl">{name}</h1>
              <h3 className="hidden md:block underline text-xs">
                Marrakech, Marrakech Tensift El Haouz, Maroc
              </h3>
            </div>

            <div className="flex ">
                <h1 className="underline px-2">save</h1>
               <AddToFavorite product={product} id={id} />
            </div>
          </div>
          <div className="md:hidden">
            <Slider className="overflow-visible image-container" {...settings}>
              {productPictures?.map((p, index) => {
                return (
                  <div key={index} className="rounded-xl overflow-hidden">
                    <LazyLoad height={224}>
                      <img
                        className="h-56 w-full object-cover object-center"
                        // src={`${imgUrl}/uploads/${p.img}`}
                        src={`${p.img}`}
                        // src={`data:image/jpeg;base64,${p.img}`}
                        alt=""
                      />
                    </LazyLoad>
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="hidden lg:grid grid-cols-[1fr,1fr] gap-2">
            <div className="w-full h-[480px] rounded-tl-xl  rounded-bl-xl overflow-hidden">
              {productPictures?.slice(0, 1).map((p, index) => {
                return (
                  <LazyLoad key={index} height={500}>
                    <img
                      className="object-cover hover:brightness-90 resize cursor-pointer transition-all duration-200 ease-in-out h-full w-full object-center"
          
                      src={`${p.img}`}
                      alt=""
                    />
                  </LazyLoad>
                );
              })}
            </div>
            <div className="grid grid-cols-2 gap-2 rounded-tr-xl rounded-br-xl overflow-hidden relative">
              {productPictures?.slice(0, 4).map((p, index) => {
                return (
                  <LazyLoad key={index} height={80} >
                    <img
                      className="h-[250px] w-ful  hover:bg-neutral-300 rounded-xl cursor-pointer transition-all duration-200 ease-in-out  object-cover"
                      // src={`${imgUrl}/uploads/${p.img}`}7
                      src={`${p.img}`}
                      alt=""
                    />
                  </LazyLoad>
                );
              })}
              <div className="absolute bottom-3 right-3">
                <button
                  type="button"
                  onClick={() => setIsOpen(true)}
                  className="bg-white px-2 py-1 border border-black rounded-full hover:bg-gray-100 transition-all duration-200 ease-in-out"
                >
                  <div className="flex items-center gap-2">
                    <svg
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                      focusable="false"
                      style={{
                        display: "block",
                        height: "16px",
                        width: "16px",
                        fill: "#000000",
                      }}
                    >
                      <path
                        d="m3 11.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-10-5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-10-5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                    <div className="  ">Show all picutres</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <section className=" py-4 md:py-12 lg:gap-32 grid lg:grid-cols-[2fr,1fr]">
            <div className="space-y-4">
              <h2 className="font-medium text-2xl">PRODUCT OVERVIEW</h2>
              <p className="text-justify tracking-wide md:leading-8 ">
                {description}
              </p>
            </div>
            <div className="shadow-lg rounded-lg h-72 mt-5 bg-white py-4 px-4">
              <div className="flex justify-between">
                <h1 className="text-lg font-semibold">
                  {formatPrice(price)} <span className="font-light"></span>
                </h1>
                <button className="underline">46 comments</button>
              </div>
              <h1 className="text-sm mt-2">
              Nutrisport. Timeless Staples, Everyday Prices
               Final Sale!{" "}
              </h1>

              <div className="space-y-5 mt-4">
                 <div className="flex gap-4">
                <AiOutlineAppstore className="font-extrabold h-10 w-7  "/>
                <h1 className="text-xl font-extrabold py-2">COMPLETE THE SET</h1>
                 </div>
 
               <button  
                onClick={() => addToCart(_id, 1, product)}
                className="  bg-black hover:bg-zinc-600 text-white font-bold py-2 px-24 ml-7 rounded-xl"> 
                <span className="text-sm font-thin ">Add to bag</span> 
               </button>


                {/* <button className="flex items-center justify-center gap-2 bg-[#003580] w-full rounded-xl text-white py-2">
                  <span>
                    <TbBrandBooking />
                  </span>
                  <a href={link_Boking}>Booking</a>
                </button> */}
                {/* <button className="bg-[#FF385C] font-semibold flex items-center justify-center gap-2 w-full rounded-xl text-white py-2">
                  <span>
                    <FaAirbnb />
                  </span>
                  <a href={link_Airbnb}>Airbnb</a>
                </button>
                <button className="bg-[#34E0A1] flex items-center font-semibold justify-center gap-2 w-full rounded-xl text-white py-2">
                  <span>
                    <FaTripadvisor />
                  </span>
                  <a href={link_TripAd}>Trip Advisor</a>
                </button> */}
              </div>
            </div>
          </section>
          <hr className="w-2/4 md:w-full mx-auto my-6 md:my-0" />
        </div>
      </div>
      {/* <SimilarRiads category={category} name={name} />
      <hr className="w-2/4 mx-auto my-10" />
      <Reviews reviews={reviews} id={id} /> */}
      <SimilarProducts category={category} name={name}/>
      <Footer />
    </>
  );
};

export default SingleProductPage;
