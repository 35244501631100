import React from "react";
import { FiHeart } from "react-icons/fi";
import { BiShoppingBag } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useCartContext } from "../context/cart_context";
import { useUsersContext } from "../context/users_context";
import UserDropdown from "./Dashboard/Dropdowns/UserDropdown";
import { useGlobalContext } from "../context/global_context";

const CartButtons = () => {
  const { user } = useUsersContext();
  const { total_items, favorite } = useCartContext();
  const { openFavorite } = useGlobalContext();

  return (
    <div className="flex items-center gap-1 pr-2 md:pr-0">
      {user ? (
        <div className="hidden md:flex md:items-center">
          <h1 className="text-white hidden lg:block">welcome {user.name}</h1>
          <UserDropdown textColor="text-white" />
        </div>
      ) : (
        <Link
          to="/signin"
          className="hidden lg:flex items-center border-b cursor-pointer text-white tracking-widest mr-5"
        >
          Sign in
        </Link>
      )}
      <div className="flex space-x-8">
        <Link
          to="/favorite"
          onMouseEnter={openFavorite}
          className="text-white text-2xl tracking-widest flex items-center"
        >
          <span className="flex items-center relative">
            <FiHeart />
            <span className="absolute -top-[10px] -right-4 bg-gray-500 font-bold rounded-full w-4 h-4 flex items-center justify-center text-xs text-white pl-1">
              {favorite.length}
            </span>
          </span>
        </Link>
        <Link
          to="/cart"
          className="text-white text-2xl tracking-widest flex items-center"
        >
          <span className="flex items-center relative">
            <BiShoppingBag />
            <span className="absolute -top-[10px] -right-3 bg-gray-500 font-bold rounded-full w-4 h-4 flex items-center justify-center text-xs text-white pl-1">
              {total_items}
            </span>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default CartButtons;
