import React, { useState } from "react";
import { FaTrash, FaEdit } from "react-icons/fa";
import { useCategoriesContext } from "../../../context/categories_context";
import { useUsersContext } from "../../../context/users_context";
import AlertCategory from "../Alert/AlertCategory";

export default function CardAddCategory() {
  const [categoryName, setCategoryName] = useState("");
  const { user, token } = useUsersContext();
  const {
    all_categories,
    addCategory,
    // getAllCategories,
    editCategory,
    deleteCategory,
    displayAlert,
    showAlert,
  } = useCategoriesContext();
  const [isEditing, setIsEditing] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const { _id } = user;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!categoryName) {
      displayAlert("Please provide a category name");
      return;
    }
    isEditing
      ? editCategory(_id, token, categoryId, categoryName)
      : addCategory(_id, token, categoryName);
    setCategoryName("");
    setIsEditing(false);
  };

  return (
    <>
      <div className="relative lg-mt-24 flex flex-col min-w-0 break-words w-full mb-10 shadow-lg rounded-lg py-4 px-5 bg-white border-0">
        <form onSubmit={handleSubmit}>
          <h6 className="text-gray-600 text-center tracking-wider text-xl font-bold mb-5">
            Categories
          </h6>
          {showAlert && <AlertCategory />}
          <div className="flex justify-between py-6">
            <input
              type="text"
              className="w-full rounded-tl-md rounded-bl-md border-none bg-gray-100 p-1 focus:rounded-tr-md focus:rounded-br-md focus:ring-[#d1b152]"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
            <button
              className="bg-[#B5942F] w-16 tracking-widest text-white active:bg-[#a3852a] font-bold uppercase px-1 py-1 rounded-tr-md rounded-br-md shadow hover:shadow-md hover:bg-[#a3852a] outline-none focus:outline-none ease-linear transition-all duration-150"
              type="submit"
            >
              {isEditing ? "Edit" : "Add"}
            </button>
          </div>
        </form>
        {all_categories.map((item, index) => {
          return (
            <div
              key={index}
              className="flex justify-between items-center px-4 py-3 hover:bg-gray-100 hover:rounded-md transition-all duration-150 ease-linear"
            >
              <p className="text-lg font-medium text-gray-500">{item.name}</p>
              <div className="flex gap-3">
                <button
                  type="button"
                  onClick={() => {
                    setIsEditing(true);
                    setCategoryName(item.name);
                    setCategoryId(item._id);
                  }}
                  className="text-green-600"
                >
                  <FaEdit />
                </button>
                <button
                  type="button"
                  onClick={() => deleteCategory(_id, token, item._id)}
                  className="text-red-600"
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
