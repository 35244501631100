import React, { useEffect } from "react";
import { useProductsContext } from "../../../../context/products_context";
import { useUsersContext } from "../../../../context/users_context";
import CardOrder from "../../Cards/CardOrder";

export default function Orders() {
  const { getAllOrders, getStatus } = useProductsContext();
  const { user, token } = useUsersContext();

  useEffect(() => {
    getAllOrders(user._id, token);
    getStatus(user._id, token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="w-full mb-12 px-4 ">
        <CardOrder />
      </div>
    </>
  );
}
