import React from 'react'
import { useProductsContext } from '../../context/products_context'
import Product from '../Product'


const SimilarProducts = ({category,name}) => {
    const { products } = useProductsContext();

    const similarProducts = products.filter(
      (p) => p.category === category && p.name !== name
    );
  
    return (
      <div className="w-[90vw] max-w-[1300px] mx-auto  md:py-8">
        <h1 className="capitalize text-center text-2xl md:text-3xl font-semibold pb-10">
        Recommended for you
        </h1>
        <div className="grid gap-y-8 gap-x-5 md:grid-cols-[1fr,1fr,1fr] lg:grid-cols-[1fr,1fr,1fr,1fr]">
          {similarProducts.slice(0, 8).map((product) => {
            return <Product key={product._id} {...product} />;
          })}
        </div>
      </div>
    );
}

export default SimilarProducts