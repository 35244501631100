import React from "react";
import {
  BiCategoryAlt,
  BiUserPin,
  BiPackage,
  BiCart,
  BiListOl,
  BiUser,
  BiKey,
} from "react-icons/bi";

import {
  // AiOutlineCalendar,
  AiOutlineShoppingCart,
  // AiOutlineAreaChart,
  // AiOutlineBarChart,
  // AiOutlineStock,
} from "react-icons/ai";
import {
  FiShoppingBag,
  // FiEdit,
  // FiPieChart,
  // FiBarChart,
  // FiCreditCard,
  // FiStar,
  // FiShoppingCart,
} from "react-icons/fi";
import { RiContactsLine } from "react-icons/ri";

export const links = [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: 2,
    text: "products",
    url: "/products",
  },
  {
    id: 3,
    text: "contact us",
    url: "/contactus",
  },
];

export const footerLinks = [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: 2,
    text: "products",
    url: "/products",
  },
  {
    id: 3,
    text: "contact us",
    url: "/contactus",
  },
  {
    id: 4,
    text: "faq",
    url: "/faq",
  },
  {
    id: 5,
    text: "refer",
    url: "/refer",
  },
];

export const sidebarLinks = [
  {
    id: 1,
    text: "dashboard",
    url: "/admin/dashboard",
    icon: <BiCategoryAlt />,
  },
  {
    id: 2,
    text: "users",
    url: "/admin/users",
    icon: <BiUserPin />,
  },
  {
    id: 3,
    text: "products",
    url: "/admin/addproduct",
    icon: <BiPackage />,
  },
  {
    id: 4,
    text: "categories",
    url: "/admin/categories",
    icon: <BiListOl />,
  },
  {
    id: 5,
    text: "orders",
    url: "/admin/orders",
    icon: <BiCart />,
  },
  {
    id: 6,
    text: "account",
    url: "/admin/account",
    icon: <BiUser />,
  },
  {
    id: 7,
    text: "security",
    url: "/admin/security",
    icon: <BiKey />,
  },
];

export const userSidebarLinks = [
  {
    id: 1,
    text: "dashboard",
    url: "/user/dashboard",
    icon: <BiCategoryAlt />,
  },
  {
    id: 2,
    text: "cart",
    url: "/user/cart",
    icon: <BiCart />,
  },
  {
    id: 3,
    text: "orders",
    url: "/user/orders",
    icon: <BiListOl />,
  },
  {
    id: 4,
    text: "account",
    url: "/user/account",
    icon: <BiUser />,
  },
  {
    id: 5,
    text: "security",
    url: "/user/security",
    icon: <BiKey />,
  },
];

export const dashboardAdminLinks = [
  {
    title: "Dashboard",
    links: [
      {
        name: "ecommerce",
        icon: <FiShoppingBag />,
      },
    ],
  },

  {
    title: "Client Facing",
    links: [
      {
        name: "orders",
        icon: <AiOutlineShoppingCart />,
      },
      {
        name: "customers",
        icon: <RiContactsLine />,
      },
      {
        name: "products",
        icon: <BiPackage />,
      },
      {
        name: "categories",
        icon: <BiListOl />,
      },
    ],
  },
  {
    title: "Settings",
    links: [
      {
        name: "account",
        icon: <BiUser />,
      },
      {
        name: "security",
        icon: <BiKey />,
      },
    ],
  },
  {
    title: "Sales",
    links: [
      {
        name: "Overview",
        icon: <BiUser />,
      },
      {
        name: "Daily",
        icon: <BiKey />,
      },
      {
        name: "Monthly",
        icon: <BiKey />,
      },
    ],
  },
];


export const dashboardUserLinks = [
  {
    title: "Dashboard",
    links: [
      {
        name: "ecommerce",
        icon: <FiShoppingBag />,
      },
    ],
  },

  {
    title: "Client Facing",
    links: [
      {
        name: "orders",
        icon: <AiOutlineShoppingCart />,
      },
      {
        name: "products",
        icon: <BiPackage />,
      },
      {
        name: "categories",
        icon: <BiListOl />,
      },
    ],
  },
  {
    title: "Settings",
    links: [
      {
        name: "account",
        icon: <BiUser />,
      },
      {
        name: "security",
        icon: <BiKey />,
      },
    ],
  },
 
];

export const headersConfig = (token) => {
  return {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const DateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

// export const API_URL = `/api`;

//for local
//  export const API_URL = `http://localhost:8000/api`;
  export const API_URL = `https://nutrisport-backend.onrender.com/api`;
