import React from "react";
import Filters from "../components/Filters";
import Navbar from "../components/Navbar";
import ProductList from "../components/ProductList";
import Sort from "../components/Sort";
import PageHero from "../components/PageHero";
import Footer from "../components/Footer";
import { useGlobalContext } from "../context/global_context";

const ProductsPage = () => {
  const { closeFavorite } = useGlobalContext();

  return (
    <>
      <Navbar bg="bg-gradient-to-l from-gray-800 to-black" />
      <PageHero title="products" />
      <div
        onMouseOver={closeFavorite}
        className="w-[90vw] my-0 pb-20 mx-auto max-w-[1170px] grid gap-y-12 gap-x-6 md:grid-cols-[200px_1fr]"
      >
        <Filters />
        <div>
          <Sort />
          <ProductList />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductsPage;
