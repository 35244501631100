  import React from "react";
  import { useCartContext } from "../context/cart_context";
  import { useNavigate } from "react-router-dom";
  import { useUsersContext } from "../context/users_context";

  const AddToFavorite = ({ product, id }) => {
    const navigate = useNavigate();
   

    const { user } = useUsersContext();
    const { addToFavorite, favorite,removeFromFavorite } = useCartContext();
    const isSelected = favorite.find((item) => item.id === id);
    
    const handleClick = () => {
      !user
        ? navigate("/signin")
        : !isSelected
        ? addToFavorite(id, product)
        : removeFromFavorite(id);
    };
    return (
      

      <button
        // onClick={() => {
        //    addToFavorite(id, product);

        // }}
         onClick={handleClick}
        className="text-3xl  lg:text-2xl transition-all duration-200 ease-linear"
      >
        <i
          className={`fa-solid  hover:text-black fa-heart ${
            isSelected ? "text-black" : "text-gray-300"
          }`}
        ></i>
      </button>
      
    );
  };
  export default AddToFavorite;




//  import React, { useEffect, useState } from "react";
//  import { useNavigate } from "react-router-dom";
//  import { useCartContext } from "../context/cart_context";
//  import { useUsersContext } from "../context/users_context";

//  const AddToFavorite = ({ product, id }) => {
//    const navigate = useNavigate();
//    const { addToFavorite, removeFromFavorite, favorite } = useCartContext();
//    const { user } = useUsersContext();
//    const isSelected = favorite.find((item) => item.id === id);
//    const handleClick = () => {
//      !user
//        ? navigate("/signin")
//        : !isSelected
//        ? addToFavorite(id, product)
//        : removeFromFavorite(id);
//    };
//    return (
//      <button className="absolute right-2 top-2" onClick={handleClick}>
//        <svg
//          viewBox="0 0 32 32"
//          xmlns="http://www.w3.org/2000/svg"
//          aria-hidden="true"
//          role="presentation"
//          focusable="false"
//          style={{
//            display: "block",
//            // fill: "rgba(0, 0, 0, 0.5)" ,
//            fill: `${isSelected ? "#1c66f2" : "rgba(0, 0, 0, 0.5)"}`,
//            height: "24px",
//            width: "24px",
//            // stroke: "#FFFFFF",
//            storke:"#040400",
//            strokeWidth: 2,
//            overflow: "visible",
//          }}
//        >
//          <path d="m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z"></path>
//        </svg>
//      </button>
//    );
//  };
//  export default AddToFavorite;