import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { imgUrl } from "../utils/constants";
import { convertBase64, formatPrice } from "../utils/helpers";
import AddToFavorite from "./AddToFavorite";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrow: true,
  // responsive: [
  //   {
  //     breakpoint: 480,
  //     settings: {
  //       dots: true,
  //       arrows: true,
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //     },
  //   },
  // ],
};

const ListView = ({ products }) => {
  return (
    <div className="grid gap-y-12 relative">
      {products.map((product) => {
        const { _id, name, description, price, productPictures } = product;
        return (
          <article
            className="lg:grid grid-cols-[auto_1fr] gap-x-8 items-center"
            key={_id}
          >
            <Slider
              className="overflow-hidden w-[90vw] mx-auto lg:w-72 rounded-xl image-container "
              {...settings}
            >
              {productPictures?.map((p, index) => {
                return (
                  <div
                    key={index}
                    className="rounded-xl overflow-hidden relative"
                  >

                     {/* <AddToFavorite  product={product} id={_id} /> */}
               
                    <Link to={`/products/${_id}`}>
                      <img
                        className="w-full h-80 object-cover object-center"
                        
                        //src={`${imgUrl}/uploads/${p.img}`}
                        // update 27/03/2023 src={`${p.img}`}
                        src={`${p.img}`}
                        alt=""
                      />
                    </Link>
                  </div>
                );
              })}
            </Slider>
          
            <div>
              <h4 className="mb-2 font-bold uppercase tracking-wider">
                {name}
              </h4>
              <h5 className="text-gray-700 font-semibold mb-3">
                {formatPrice(price)}
              </h5>
              <p className="max-w-[45em] mb-4">
                {description.substring(0, 150)}...
              </p>
              <Link
                to={`/products/${_id}`}
                className="text-xs inline-block py-1 px-2 bg-[#1d68f2] rounded-md shadow-md tracking-wider text-white hover:bg-blue-700 transition-all duration-200 ease-linear"
              >
                Details
              </Link>
             
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default ListView;
