import React, { useContext, useReducer, useEffect } from "react";
import reducer from "../reducers/global_reducer";
import {
  FAVORITE_CLOSE,
  FAVORITE_OPEN,
  FILTER_ORDERS,
  FILTER_USER_ORDERS,
  LOAD_ORDERS,
  NAVBAR_CLOSE,
  NAVBAR_OPEN,
  UPDATE_FILTERS,
  UPDATE_USER_FILTERS,
} from "../actions";
import { useProductsContext } from "./products_context";

const AppContext = React.createContext();

const initialState = {
  sidebar_open: false,
  favorite_open: false,
  all_orders: [],
  filtered_orders: [],
  sort: "price-lowest",
  filters: {
    text: "",
    statusFilter: "All Orders",
  },
  all_user_orders: [],
  filtered_user_orders: [],
  user_filters: {
    statusFilter: "All",
  },
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { orders, user_orders } = useProductsContext();

  const openSidebar = () => {
    dispatch({ type: NAVBAR_OPEN });
  };

  const closeSidebar = () => {
    dispatch({ type: NAVBAR_CLOSE });
  };

  const openFavorite = () => {
    dispatch({ type: FAVORITE_OPEN });
  };

  const closeFavorite = () => {
    dispatch({ type: FAVORITE_CLOSE });
  };

  useEffect(() => {
    dispatch({ type: LOAD_ORDERS, payload: { orders, user_orders } });
  }, [orders, user_orders]);

  useEffect(() => {
    dispatch({ type: FILTER_ORDERS });
    //  dispatch({ type: SORT_PRODUCTS });
  }, [state.filters]);

  useEffect(() => {
    dispatch({ type: FILTER_USER_ORDERS });
    //  dispatch({ type: SORT_PRODUCTS });
  }, [state.user_filters]);

  const updateFilters = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    dispatch({ type: UPDATE_FILTERS, payload: { name, value } });
  };

  const updateUserFilters = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    dispatch({ type: UPDATE_USER_FILTERS, payload: { name, value } });
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        updateFilters,
        updateUserFilters,
        openSidebar,
        closeSidebar,
        openFavorite,
        closeFavorite,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
// make sure use
export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
