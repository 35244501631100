import {
  UPDATE_FILTERS,
  LOAD_ORDERS,
  FILTER_ORDERS,
  UPDATE_USER_FILTERS,
  FILTER_USER_ORDERS,
  NAVBAR_OPEN,
  NAVBAR_CLOSE,
  FAVORITE_OPEN,
  FAVORITE_CLOSE,
} from "../actions";

const global_reducer = (state, action) => {
  if (action.type === NAVBAR_OPEN) {
    return {
      ...state,
      sidebar_open: true,
    };
  }
  if (action.type === NAVBAR_CLOSE) {
    return {
      ...state,
      sidebar_open: false,
    };
  }

  if (action.type === FAVORITE_OPEN) {
    return {
      ...state,
      favorite_open: true,
    };
  }
  if (action.type === FAVORITE_CLOSE) {
    return {
      ...state,
      favorite_open: false,
    };
  }

  if (action.type === LOAD_ORDERS) {
    const { orders, user_orders } = action.payload;
    return {
      ...state,
      all_orders: [...orders],
      filtered_orders: [...orders],
      all_user_orders: [...user_orders],
      filtered_user_orders: [...user_orders],
    };
  }

  //   if (action.type === UPDATE_SORT) {
  //     return { ...state, sort: action.payload };
  //   }

  //   if (action.type === SORT_PRODUCTS) {
  //     const { sort, filtered_products } = state;
  //     let tempOrders = [...filtered_products];
  //     if (sort === "price-lowest") {
  //       tempOrders = tempOrders.sort((a, b) => a.price - b.price);
  //     }
  //     if (sort === "price-highest") {
  //       tempOrders = tempOrders.sort((a, b) => b.price - a.price);
  //     }
  //     if (sort === "name-a") {
  //       tempOrders = tempOrders.sort((a, b) => a.name.localeCompare(b.name));
  //     }
  //     if (sort === "name-z") {
  //       tempOrders = tempOrders.sort((a, b) => b.name.localeCompare(a.name));
  //     }
  //     return { ...state, filtered_products: tempOrders };
  //   }

  if (action.type === UPDATE_FILTERS) {
    const { name, value } = action.payload;
    return { ...state, filters: { ...state.filters, [name]: value } };
  }

  if (action.type === UPDATE_USER_FILTERS) {
    const { name, value } = action.payload;
    return { ...state, user_filters: { ...state.user_filters, [name]: value } };
  }

  if (action.type === FILTER_ORDERS) {
    const { all_orders } = state;
    const { text, statusFilter } = state.filters;

    let tempOrders = [...all_orders];

    //text
    if (text) {
      tempOrders = tempOrders.filter((order) => {
        return order.user.name.toLowerCase().startsWith(text);
      });
    }
    //status
    if (statusFilter !== "All Orders") {
      tempOrders = tempOrders.filter((order) => order.status === statusFilter);
    }
    return { ...state, filtered_orders: tempOrders };
  }

  if (action.type === FILTER_USER_ORDERS) {
    const { all_user_orders } = state;
    const { text, statusFilter } = state.user_filters;

    let tempOrders = [...all_user_orders];

    //text
    if (text) {
      tempOrders = tempOrders.filter((order) => {
        return order.status.toLowerCase().startsWith(text);
      });
    }

    if (statusFilter !== "All") {
      tempOrders = tempOrders.filter((order) => order.status === statusFilter);
    }
    return { ...state, filtered_user_orders: tempOrders };
  }

  //   if (action.type === CLEAR_FILTERS) {
  //     return {
  //       ...state,
  //       filters: {
  //         ...state.filters,
  //         text: "",
  //         company: "all",
  //         category: "all",
  //         color: "all",
  //         price: state.filters.max_price,
  //         shipping: false,
  //       },
  //     };
  //   }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default global_reducer;
