import React from "react";

const OrderList = ({ user }) => {

  const { amount } = user.history.reduce(
    (total, history) => {
      const { amount } = history;
      total.amount += amount;

      return total;
    },
    {
      amount: 0,
    }
  );

  return <td className="px-6 py-4">{amount}</td>;
};

export default OrderList;
