import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useProductsContext } from "../../../context/products_context";
import { useUsersContext } from "../../../context/users_context";
import OrderProductList from "./OrderProductList";
import ProgressStatus from "./ProgressStatus";

const CardUserSingleOrder = () => {
  const { idOrder } = useParams();
  const { getSingleOrder, single_order } = useProductsContext();
  const { user, token } = useUsersContext();

  useEffect(() => {
    getSingleOrder(user?._id, token, idOrder);
    // eslint-disable-next-line
  }, [idOrder]);

  return (
    <div className="px-10">
      <div className="flex items-center flex-col md:flex-row md:justify-between mb-10">
        <h1 className="text-2xl md:text-3xl font-bold">{`Order #${single_order?._id?.substr(
          single_order?._id.length - 4
        )}`}</h1>
        <h1 className="font-medium">
          <span className="text-gray-400">Order placed </span>
          {new Date(single_order?.createdAt).toDateString()}
        </h1>
      </div>
      <div className="shadow-md rounded-lg bg-white py-8">
        <div className="flex justify-between flex-wrap">
          <OrderProductList single_order={single_order} />
          <div className="px-10 py-5 md:p-0">
            <h1 className="font-semibold">Delivery address</h1>
            <p className="text-gray-500 font-medium">{single_order?.address}</p>
          </div>
          <div className="space-y-2 px-10 py-5 md:px-5 md:py-0">
            <h1 className="font-semibold">Shipping updates</h1>
            <h1 className="text-gray-500 font-medium">
              {single_order?.user?.email}
            </h1>
            <h1 className="text-gray-500 font-medium">
              +{single_order?.phoneNumber}
            </h1>
            <Link
              to="/user/account"
              className="text-green-700 inline-block tracking-wider font-bold"
            >
              Edit
            </Link>
          </div>
        </div>
        <hr className="mb-20 md:mb-10" />
        <ProgressStatus status={single_order?.status} />
        <div className="flex justify-end mt-8 mr-5">
          <Link
            className="bg-gray-600 text-white rounded-full p-2"
            to="/user/orders"
          >
            Back To Orders
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardUserSingleOrder;
