import React from "react";
import Banner from "../components/Banner";
import CategoriesList from "../components/CategoriesList";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Highlight from "../components/Highlight";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import SingleProductSlider from "../components/SingleProductSlider";
import Whatsapp from "../components/Whatsapp";
import Carasoul from "../components/Carasoul";
import ProductList from "../components/ProductList";
import UserCategories from "../components/Dashboard/views/User/UserCategories";

const HomePage = () => {
  return (
    <>
     <Navbar bg="md:bg-transparent bg-black" /> 
      {/* <Navbar bg="bg-black" /> */}
      <Hero />
      {/* <CategoriesList /> */}
      <Carasoul/>
      <Banner />
      <SingleProductSlider />
      <Highlight />
      <UserCategories/>
      <Newsletter />
      <Footer />
      <Whatsapp />
    </>
  );
};

export default HomePage;
