export const TOKEN_SUCCESS = "TOKEN_SUCCESS";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAILED = "PAYMENT_FAILED";
export const GET_ALL_ORDERS_SUCCESS = "GET_ALL_ORDERS_SUCCESS";
export const GET_ALL_STATUS_SUCCESS = "GET_ALL_STATUS_SUCCESS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_BEGIN = "GET_ALL_USERS_BEGIN";

export const NAVBAR_OPEN = "NAVBAR_OPEN";
export const NAVBAR_CLOSE = "NAVBAR_CLOSE";
export const FAVORITE_OPEN = "FAVORITE_OPEN";
export const FAVORITE_CLOSE = "FAVORITE_CLOSE";
export const REGISTER_USER_BEGIN = "REGISTER_USER_BEGIN";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGIN_USER_BEGIN = "LOGIN_USER_BEGIN";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const DISPLAY_ALERT = "DISPLAY_ALERT";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const SHOW_PROFILE_SUCCESS = "SHOW_PROFILE_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

//categories
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_ERROR = "GET_ALL_CATEGORIES_ERROR";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";

export const IS_EDITING = "IS_EDITING";
export const IS_NOT_EDITING = "IS_NOT_EDITING";
export const GET_PRODUCTS_BEGIN = "GET_PRODUCTS_BEGIN";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const GET_SINGLE_PRODUCT_BEGIN = "GET_SINGLE_PRODUCT_BEGIN";
export const GET_SINGLE_PRODUCT_SUCCESS = "GET_SINGLE_PRODUCT_SUCCESS";
export const GET_SINGLE_PRODUCT_ERROR = "GET_SINGLE_PRODUCT_ERROR";
export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const SET_GRIDVIEW = "SET_GRIDVIEW";
export const SET_LISTVIEW = "SET_LISTVIEW";
export const UPDATE_SORT = "UPDATE_SORT";
export const SORT_PRODUCTS = "SORT_PRODUCTS";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const FILTER_PRODUCTS = "FILTER_PRODUCTS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const ADD_TO_FAVORITE = "ADD_TO_FAVORITE";
export const REMOVE_FAVORITE_ITEM = "REMOVE_FAVORITE_ITEM";
export const TOGGLE_CART_ITEM_AMOUNT = "TOGGLE_CART_ITEM_AMOUNT";
export const CLEAR_CART = "CLEAR_CART";
export const CLEAR_FAVORITE = "CLEAR_FAVORITE";
export const COUNT_CART_TOTALS = "COUNT_CART_TOTALS";
export const COUNT_FAVORITE_TOTALS = "COUNT_FAVORITE_TOTALS";

//status and orders
export const LOAD_ORDERS = "LOAD_ORDERS";
export const FILTER_ORDERS = "FILTER_ORDERS";
export const GET_ONE_ORDER_SUCCESS = "GET_ONE_ORDER_SUCCESS";
export const GET_USER_ORDERS_SUCCESS = "GET_USER_ORDERS_SUCCESS";

//user
export const UPDATE_USER_FILTERS = "UPDATE_USER_FILTERS";
export const FILTER_USER_ORDERS = "FILTER_USER_ORDERS";
