import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import {
  ADD_CATEGORY_SUCCESS,
  CLEAR_ALERT,
  DELETE_CATEGORY_SUCCESS,
  DISPLAY_ALERT,
  EDIT_CATEGORY_SUCCESS,
  GET_ALL_CATEGORIES_SUCCESS,
} from "../actions";
import reducer from "../reducers/categories_reducer";
import { API_URL, headersConfig } from "../utils/constants";

const initialState = {
  isLoading: false,
  showAlert: false,
  alertText: "",
  alertType: "",
  all_categories: [],
};

const CategoriesContext = React.createContext();

export const CategoriesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const displayAlert = (msg) => {
    dispatch({ type: DISPLAY_ALERT, payload: msg });
    clearAlert();
  };

  const clearAlert = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
    }, 3000);
  };

  const addCategory = async (id, token, name) => {
    try {
      await axios.post(
        `${API_URL}/category/create/${id}`,
        { name },
        headersConfig(token)
      );
      dispatch({ type: ADD_CATEGORY_SUCCESS });
      await getAllCategories(id, token);
    } catch (error) {}
    clearAlert();
  };

  const getAllCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/category/allcategories`);
      const { categories } = response.data;
      dispatch({ type: GET_ALL_CATEGORIES_SUCCESS, payload: categories });
    } catch (error) {}
  };

  useEffect(() => {
    getAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editCategory = async (id, token, idCategory, name) => {
    try {
      await axios.put(
        `${API_URL}/category/${idCategory}/${id}`,
        { name },
        headersConfig(token)
      );
      dispatch({ type: EDIT_CATEGORY_SUCCESS });
      await getAllCategories();
    } catch (error) {}
    clearAlert();
  };

  const deleteCategory = async (id, token, idCategory) => {
    try {
      const response = await axios.delete(
        `${API_URL}/category/${idCategory}/${id}`,
        headersConfig(token)
      );
      dispatch({ type: DELETE_CATEGORY_SUCCESS });
      await getAllCategories();
      console.log(response);
    } catch (error) {}
    clearAlert();
  };

  return (
    <CategoriesContext.Provider
      value={{
        ...state,
        addCategory,
        getAllCategories,
        deleteCategory,
        editCategory,
        displayAlert,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategoriesContext = () => {
  return useContext(CategoriesContext);
};
