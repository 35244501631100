import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import { links } from "../utils/constants";
import { FaBars } from "react-icons/fa";
import { useGlobalContext } from "../context/global_context";
import CartButtons from "./CartButtons";
// import FavoriteDropdown from "./FavoriteDropdown";

const Navbar = ({ bg }) => {
  const { openSidebar, favorite_open } = useGlobalContext();
 

  return (
    <nav
      className={`h-20 flex items-center justify-center relative z-[1]  ${bg}`}
    >
      {/* {favorite_open && <FavoriteDropdown />} */}
      <div className="w-[90vw] max-w-[1170px] md:grid md:grid-cols-[auto_1fr_auto] md:items-center">
        <div className="flex justify-between items-center">
          <div className="flex">
            <button
              onClick={openSidebar}
              className="text-2xl py-1 px-3 rounded-md text-white cursor-pointer md:hidden"
            >
              <FaBars />
            </button>
            <Link to="/">
              <img
                className="h-42 w-32 cursor-pointer"
                src={logo}
                alt="brand logo"
              />
            </Link>
          </div>
          <div className="md:hidden">
            <CartButtons />
          </div>
        </div>
        <ul className="hidden md:grid md:justify-self-center md:grid-cols-[1fr_1fr_1fr] md:text-center md:items-center">
          {links.map((link) => {
            return (
              <li key={link.id} className="md:h-full">
                <Link
                  to={link.url}
                  className="md:h-full md:text-white md:font-semibold link-btn md:inline-block md:text-lg md:capitalize md:tracking-wider md:w-40"
                >
                  {link.text}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="hidden md:block">
          <CartButtons />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
