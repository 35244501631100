import React from "react";
import { useCartContext } from "../context/cart_context";
import { convertBase64, formatPrice } from "../utils/helpers";
import AmountButtons from "./AmountButtons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LazyLoad from "react-lazy-load";

const settings = {
  dotsClass: "slick-dots slick-thumb",
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const CartItem = ({ id, productPictures, name, price, amount }) => {
  const { removeItem, toggleAmount } = useCartContext();

  const increase = () => {
    toggleAmount(id, "inc");
  };
  const decrease = () => {
    toggleAmount(id, "dec");
  };

  return (
    <div className="flex items-center hover:bg-gray-100 transition-all duration-500 ease-linear  px-6 py-5">
      <div className="flex w-full lg:w-2/5 ">
        <div className="w-28 ">
        <Slider className="overflow-visible image-container" {...settings}>
            {productPictures?.map((p, index) => {
          return (
            <div key={index} className="rounded-xl overflow-hidden ">
            
                <LazyLoad height={224} width={90}>
                  <img
                    className="h-40 z-50  w-screen
                     object-cover object-center"
                    src={`${p.img}`}
                    alt=""
                  />
                </LazyLoad>
            </div>
          );
        })}
      </Slider>
        </div>

        <div className="flex flex-col justify-evenly ml-6 flex-grow">
          <span className="font-bold text-sm">{name}</span>
          <span className="md:hidden text-center w-1/5 font-semibold text-sm">
            {formatPrice(price)}
          </span>
          <button
            onClick={() => removeItem(id)}
            type="button"
            className="font-semibold w-12 hover:text-red-500 text-gray-500 text-xs"
          >
            Remove
          </button>
        </div>
      </div>
      <div className="flex justify-center w-1/5">
        <AmountButtons
          amount={amount}
          increase={increase}
          decrease={decrease}
        />
      </div>
      <span className="hidden lg:block text-center w-1/5 font-semibold text-sm">
        {formatPrice(price)}
      </span>
      <span className="text-center hidden lg:block w-1/5 font-semibold text-sm">
        {formatPrice(price * amount)}
      </span>
    </div>
  );
};

export default CartItem;
