export function parseJwt(token) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

export function convertBase64(photo) {
  const base64String = btoa(
    new Uint8Array(photo).reduce(function (data, byte) {
      return data + String.fromCharCode(byte);
    }, "")
  );
  return base64String;
}

export const formatPrice = (number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
};

export const getUniqueValues = (data) => {
  let unique = data.map((item) => {
    return { name: item.name, _id: item._id };
  });

  return [{ name: "all", _id: "all" }, ...new Set(unique)];
};
