import React from "react";
// extra imports
import { useCartContext } from "../context/cart_context";
import { Link } from "react-router-dom";
import PageHero from "../components/PageHero";
import BrainTreeCheckout from "../components/BrainTreeCheckout";
import Navbar from "../components/Navbar";
import { useGlobalContext } from "../context/global_context";

const CheckoutPage = () => {
  const { cart } = useCartContext();
  const { closeSubmenu } = useGlobalContext();
  return (
    <main>
      <Navbar bg="bg-black" />
      <PageHero title="checkout" />
      <div className="page" onMouseOver={closeSubmenu}>
        {cart.length < 1 ? (
          <div className="empty">
            <h2>your cart is empty</h2>
            <Link to="products" className="btn">
              fill it
            </Link>
          </div>
        ) : (
          <BrainTreeCheckout />
        )}
      </div>
    </main>
  );
};

export default CheckoutPage;
