import React, { useState } from "react";
import { useUsersContext } from "../../../context/users_context";
import Alert from "../../Alert";

// components

export default function CardSecurity() {
  const { user, token, displayAlert, changePassword, showAlert } =
    useUsersContext();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { _id } = user;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!currentPassword || !newPassword || !confirmPassword) {
      displayAlert("please Provide all values");
      return;
    }
    if (newPassword !== confirmPassword) {
      displayAlert("new and confirm password does not match");
      return;
    }
    changePassword(_id, token, currentPassword, newPassword);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 lg:mb-3 shadow-lg rounded-lg bg-gray-50 border-0">
        <div className="rounded-t bg-white mb-0 px-4 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-600 tracking-wider text-xl font-bold">
              Change your password
            </h6>
          </div>
        </div>
        <div className="flex-auto px-6 lg:px-10 py-10 pt-0">
          <form onSubmit={handleSubmit}>
            <h6 className="text-gray-400 tracking-wider text-sm mt-3 mb-6 font-bold uppercase">
              Change your password at any time
            </h6>
            {showAlert && (
              <div className="mt-3">
                <Alert />
              </div>
            )}
            <div className="flex flex-wrap lg:flex-col gap-3">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    placeholder="Current Password"
                    className="w-full border-gray-300 rounded-lg shadow-sm focus:border-[#d1b152] focus:ring-[#d1b152]"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full border-gray-300 rounded-lg shadow-sm focus:border-[#d1b152] focus:ring-[#d1b152]"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full">
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full border-gray-300 rounded-lg shadow-sm focus:border-[#d1b152] focus:ring-[#d1b152]"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-6">
              <button
                className="bg-[#B5942F] tracking-widest text-white active:bg-[#a3852a] font-bold uppercase text-sm px-5 py-3 rounded-lg shadow hover:shadow-md hover:bg-[#a3852a] outline-none focus:outline-none ease-linear transition-all duration-150"
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
