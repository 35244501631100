import React, { useEffect, useState } from "react";
import { useCartContext } from "../context/cart_context";
import { useUsersContext } from "../context/users_context";
import DropIn from "braintree-web-drop-in-react";
import { formatPrice } from "../utils/helpers";
import { useProductsContext } from "../context/products_context";
import PaymentAlert from "./PaymentAlert";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const BrainTreeCheckout = () => {
  const navigate = useNavigate();

  const { cart, total_amount, shipping_fee } = useCartContext();
  const { user, token } = useUsersContext();
  const [instanceData, setInstanceData] = useState({});
 
  const {
    getBraintreeToken,
    tokenBrain,
    processPayment,
    createOrder,
    showAlert,
    alertType,
  } = useProductsContext();
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    getBraintreeToken(user?._id, token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (alertType === "success") {
      setTimeout(() => {
        navigate("/products");
      }, 3000);
    }
  }, [alertType, navigate]);

  const buy = () => {
    instanceData.requestPaymentMethod().then((data) => {
      
      let paymentData = {
        amount: total_amount + shipping_fee,
        paymentMethodNonce: data?.nonce,
        deviceData: data?.deviceData,
      };
      processPayment(user?._id, token, paymentData).then((data) => {
        let orderData = {
          products: cart,
          transactionId: data?.data?.transaction?.id,
          amount: data?.data?.transaction?.amount,
          address: address,
          phoneNumber: phone,
        };
        createOrder(user?._id, token, orderData);
      
      });
    });
  };

  const handlePayment = () => {
    if (address && phone) {
      buy();
    }
  };

  return (
    <div className="lg:col-span-2 col-span-3 bg-gray-100 space-y-8 px-12 py-5">
      <div className="mt-8 p-4 relative flex flex-col sm:flex-row sm:items-center bg-white shadow rounded-md">
        <div className="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
          <div className="text-yellow-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 sm:w-5 h-6 sm:h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className="text-sm font-medium ml-3">Checkout</div>
        </div>
        <div className="text-sm tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">
          Complete your shipping and payment details below.
        </div>
        <div className="absolute sm:relative sm:top-auto sm:right-auto ml-auto right-4 top-4 text-gray-400 hover:text-gray-800 cursor-pointer">
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="rounded-md w-full lg:w-96">
          <h2 className="uppercase tracking-wide text-lg font-semibold text-gray-700 mt-2 mb-4">
            Shipping & Billing Information
          </h2>
          <form>
            <div className="relative z-0 mb-6 w-full group">
              <input
                type="text"
                name="floating_first_name"
                id="floating_first_name"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                defaultValue={user?.name}
              />
              <label
                htmlFor="floating_first_name"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Full name
              </label>
            </div>
            <div className="relative z-0 mb-6 w-full group">
              <input
                type="email"
                name="floating_email"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required=""
                defaultValue={user?.email}
              />
              <label
                htmlFor="floating_email"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Email address
              </label>
            </div>
            <div className="relative z-0 mb-6 w-full group">
              <input
                type="text"
                name="floating_address"
                id="floating_address"
                className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                  !address && "required:border-red-600"
                }`}
                placeholder=" "
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <label
                htmlFor="floating_address"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Address
              </label>
            </div>
            <div className="relative z-0 mb-6 w-full group">
              <PhoneInput
                value={phone}
                onChange={setPhone}
                placeholder="Enter Phone Number"
              />
            </div>
          </form>
        </div>
        <div className="rounded-md">
          <section>
            <h2 className="uppercase tracking-wide text-lg font-semibold text-gray-700 my-2">
              Payment Information
            </h2>
            {showAlert && <PaymentAlert />}
            {!tokenBrain ? (
              <div className="text-center">
                <svg
                  role="status"
                  className="inline mr-2 w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            ) : (
              <div>
                <DropIn
                  options={{
                    authorization: tokenBrain,
                    dataCollector: true,
                    paypal: {
                      flow: "vault",
                    },
                    googlePay: {
                      googlePayVersion: 2,
                      merchantId: "merchant-id-from-google",
                      transactionInfo: {
                        totalPriceStatus: "FINAL",
                        totalPrice: "20",
                        currencyCode: "USD",
                      },
                      allowedPaymentMethods: [
                        {
                          type: "CARD",
                          parameters: {
                            billingAddressRequired: true,
                            billingAddressParameters: {
                              format: "FULL",
                            },
                          },
                        },
                      ],
                    },
                  }}
                  onInstance={(instance) => setInstanceData(instance)}
                />
                <button
                  onClick={handlePayment}
                  className="submit-button px-4 py-3 mt-5 rounded-full bg-gray-600 hover:bg-gray-700 text-white focus:ring focus:outline-none w-full text-xl font-semibold transition-colors"
                >
                  {`Pay ${formatPrice(shipping_fee + total_amount)}`}
                </button>
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default BrainTreeCheckout;
