import {ADD_CATEGORY_SUCCESS, CLEAR_ALERT, DELETE_CATEGORY_SUCCESS, DISPLAY_ALERT, EDIT_CATEGORY_SUCCESS, GET_ALL_CATEGORIES_SUCCESS } from "../actions";


const categories_reducer = (state, action) => {
    if (action.type === DISPLAY_ALERT) {
        return {
          ...state,
          showAlert: true,
          alertType: "danger",
          alertText: action.payload,
        };
      }
    if(action.type === GET_ALL_CATEGORIES_SUCCESS) {
        return {
            ...state,
            all_categories: action.payload.reverse(),
        }
    }
    if(action.type === ADD_CATEGORY_SUCCESS) {
        return {
            ...state,
            showAlert:true,
            alertType:'success',
            alertText:'Category created successfully'
        }
    }
    if(action.type === EDIT_CATEGORY_SUCCESS) {
        return {
            ...state,
            showAlert:true,
            alertType:'success',
            alertText:'Category edited successfully'
        }
    }
    if(action.type === DELETE_CATEGORY_SUCCESS) {
        return {
            ...state,
            showAlert:true,
            alertType:'success',
            alertText:'Category deleted successfully'
        }
    }
    if (action.type === CLEAR_ALERT) {
        return {
          ...state,
          showAlert: false,
          alertType: "",
          alertText: "",
        };
    }
    throw new Error(`No Matching "${action.type}" - action type`);
};

export default categories_reducer;
