import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";

import {
  CLEAR_ALERT,
  DELETE_PRODUCT_SUCCESS,
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_STATUS_SUCCESS,
  GET_ONE_ORDER_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCTS_SUCCESS,
  GET_SINGLE_PRODUCT_BEGIN,
  GET_SINGLE_PRODUCT_ERROR,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_USER_ORDERS_SUCCESS,
  IS_EDITING,
  PAYMENT_FAILED,
  PAYMENT_SUCCESS,
  TOKEN_SUCCESS,
} from "../actions";

import reducer from "../reducers/products_reducer";

import { API_URL, headersConfig } from "../utils/constants";


const initialState = {
  isLoading: false,
  showAlert: false,
  alertText: "",
  alertType: "",
  products_loading: false,
  products_error: false,
  products: [],
  isEditing: false,
  single_product_loading: false,
  single_product_error: false,
  single_product: {},
  orders: [],
  user_orders: [],
  single_order: {},
  status: [],
  tokenBrain: "",
};

const ProductsContext = React.createContext();

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  //   const displayAlert = (msg) => {
  //     dispatch({ type: DISPLAY_ALERT, payload: msg });
  //     clearAlert();
  //   };

  const clearAlert = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
    }, 3000);
  };

  const editing = () => {
    dispatch({ type: IS_EDITING });
  };

  const addProduct = async (id, token, formdata) => {
    try {
     const res= await axios.post(
        `${API_URL}/product/create/${id}`,
        formdata,
        headersConfig(token)
      );
      console.log(res)
      await getAllProducts();
    } catch (error) {
      console.log(error);
    }
  };

  const getAllProducts = async () => {
    try {
      const response = await axios.get(`${API_URL}/product/getallproduct`);
      const { products } = response.data.data;
      dispatch({
        type: GET_PRODUCTS_SUCCESS,
        payload: products,
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_PRODUCTS_ERROR });
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const getSingleProduct = async (id) => {
    dispatch({ type: GET_SINGLE_PRODUCT_BEGIN });
    try {
      const response = await axios.get(`${API_URL}/product/${id}`);
      const { product: singleProduct } = response.data;
      dispatch({ type: GET_SINGLE_PRODUCT_SUCCESS, payload: singleProduct });
    } catch (error) {
      dispatch({ type: GET_SINGLE_PRODUCT_ERROR });
    }
  };

  const editProduct = async (id, token, idProduct, formdata) => {
    try {
      await axios.put(
        `${API_URL}/product/${idProduct}/${id}`,
        formdata,
        headersConfig(token)
      );
      // dispatch({ type: EDIT_CATEGORY_SUCCESS });
      dispatch({ type: IS_EDITING});
      await getAllProducts();
    } catch (error) {}
    // clearAlert();
  };

  const deleteProduct = async (id, token, idProduct) => {
    try {
      await axios.delete(
        `${API_URL}/product/${idProduct}/${id}`,
        headersConfig(token)
      );
      dispatch({ type: DELETE_PRODUCT_SUCCESS });
      await getAllProducts();
    } catch (error) {}
    // clearAlert();
  };

  const getBraintreeToken = async (id, tokenUser) => {
    try {
      const res = await axios.get(
        `${API_URL}/braintree/getToken/${id}`,
        headersConfig(tokenUser)
      );
      const { token } = res.data;
      dispatch({ type: TOKEN_SUCCESS, payload: token });
    } catch (error) {}
    // clearAlert();
  };
  // processing the payment
  const processPayment = async (id, tokenUser, paymentData) => {
    try {
      const res = await axios.post(
        `${API_URL}/braintree/checkout/${id}`,
        paymentData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenUser}`,
          },
        }
      );
      dispatch({ type: PAYMENT_SUCCESS });
      clearAlert();
      return res;
    } catch (error) {
      dispatch({ type: PAYMENT_FAILED });
    }
    clearAlert();
  };

  const createOrder = async (id, tokenUser, orderData) => {
    try {
      await axios.post(`${API_URL}/order/create/${id}`, orderData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenUser}`,
        },
      });
    } catch (error) {}
    // clearAlert();
  };

  const getAllOrders = async (id, tokenUser) => {
   
    try {
      const res = await axios.get(`${API_URL}/order/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenUser}`,
        },
      });
      const { data } = res;
      
      dispatch({ type: GET_ALL_ORDERS_SUCCESS, payload: data });
    } catch (error) {
      console.log(error);
    }
    // clearAlert();
  };

  const getSingleOrder = async (id, tokenUser, idOrder) => {
    try {
      const res = await axios.get(`${API_URL}/order/${id}/${idOrder}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenUser}`,
        },
      });
      const { order } = res.data;
      dispatch({ type: GET_ONE_ORDER_SUCCESS, payload: order });
    } catch (error) {
      console.log(error);
    }
    // clearAlert();
  };

  const getUserOrders = async (id, tokenUser) => {
    try {
      const res = await axios.get(`${API_URL}/order/ordersuser/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenUser}`,
        },
      });
      const { ordersUser } = res.data;
      dispatch({ type: GET_USER_ORDERS_SUCCESS, payload: ordersUser });
    } catch (error) {
      console.log(error);
    }
    // clearAlert();
  };

  const getStatus = async (id, tokenUser) => {
    try {
      const res = await axios.get(`${API_URL}/order/status/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenUser}`,
        },
      });
      const { data } = res;
      dispatch({ type: GET_ALL_STATUS_SUCCESS, payload: data });
    } catch (error) {
      console.log(error);
    }
    // clearAlert();
  };
  const updateStatus = async (id, tokenUser, orderId, status) => {
    console.log();
    try {
      const res = await axios.patch(
        `${API_URL}/order/${orderId}/status/${id}`,
        { status },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenUser}`,
          },
        }
      );
      console.log(res);
      // dispatch({ type: GET_ALL_STATUS_SUCCESS, payload: data });
    } catch (error) {
      console.log(error);
    }
    // clearAlert();
  };
  return (
    <ProductsContext.Provider
      value={{
        ...state,
        addProduct,
        getAllProducts,
        getSingleProduct,
        editProduct,
        deleteProduct,
        editing,
        getBraintreeToken,
        processPayment,
        createOrder,
        getAllOrders,
        getStatus,
        updateStatus,
        getSingleOrder,
        getUserOrders,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => {
  return useContext(ProductsContext);
};
