import React from "react";
import { Link } from "react-router-dom";
import { formatPrice } from "../utils/helpers";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LazyLoad from "react-lazy-load";
import { imgUrl } from "../utils/constants";
import AddToFavorite from "./AddToFavorite";
import { TbBrandBooking } from "react-icons/tb";
import { BiShoppingBag } from "react-icons/bi";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import { useCartContext } from "../context/cart_context";
import { useUsersContext } from "../context/users_context";
import { useProductsContext } from "../context/products_context";




const settings = {
   dotsclass: "slick-dots slick-thumb",
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,


  };

  

const Product = ({ _id, name, description,quantity,category, price, productPictures }) => {

  const { user, token } = useUsersContext();
  const { deleteProduct, editing } = useProductsContext();
  const { addToCart } = useCartContext();
  const product = { _id,name, description,quantity ,price, productPictures };
  


  return (
    <div className="w-[90vw] cursor-pointer mx-auto md:w-72 rounded-xl relative">
      <Slider className="overflow-visible  image-container" {...settings}>
        {productPictures?.map((p, index) => {
          return (
           
            <div key={index} className="rounded-xl overflow-hidden hover:border border-zinc-800">
              <Link to={`/products/${_id}`}>

                <LazyLoad height={280}>    
                          <img
                            className="h-72  w-full object-cover object-center bg-neutral-300 "
                            src={`${p.img}`}
                            alt=""
                          /> 
               
                </LazyLoad>
                
              </Link>
               {/* <button  
                onClick={() => addToCart(_id, 1, product)}
                // classNameName=" text-gray-600   text-3xl lg:text-2xl hover:text-black hover:font-bold transition-all duration-200 ease-linear"
                //       >
                //   <BiShoppingBag/> 
                classNameName="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"> Add To Cart
              </button> */}
            </div>

          );
        })}

</Slider>




      
        {/* add quick view */}
        
        
  


         {/* end of quick view */}
         
      
          

               {/* first solution*/}
      {/* <div>
        <h1 classNameName="font-semibold capitalize mb-1">{name}</h1>
        <h1>
          <span classNameName="font-semibold">{formatPrice(price)}</span> 
        </h1>    
      </div> */}




            {/* second solution*/}
            <div className="py-2 px-5">
               <h1 className=" font-semibold text-xs ">{name}</h1>
          <div className="gap-3 flex justify-start"> 
              <AddToFavorite product={product} id={_id}  /> 
              <button  
              onClick={() => addToCart(_id, 1, product)}
               className=" h- text-gray-600 text-3xl lg:text-2xl hover:text-black hover:font-bold transition-all duration-200 ease-linear"
                     >
                 <BiShoppingBag/> 
           
               </button>
            <button className="font-bold rounded-lg text-sm ml-auto">
            {formatPrice(price)}
          </button>
            </div>  

        {/* <p classNameName="text-gray-500 text-xs">{`${description}`}</p> */}
        <div
          className={`flex justify-end ${
            user?.role === 1 && "justify-between"
          } mt-2`}
        >
          {user?.role === 1 && (
            <div className="flex">
              <Link
                to="/admin/products"
                 onClick={editing}
                 state={{
                   _id,
                   name,
                   price,
                   quantity,
                   description,
                   category,
                   productPictures,
                 }}
                className="p-2 inline-block rounded-full bg-green-500 text-white mr-1 my-1 hover:bg-green-600 focus:outline-none focus:bg-green-700"
              >
                <FaRegEdit />
                
              </Link>
              <span
                onClick={() => {
                  deleteProduct(user?._id, token, _id);
                }}
                className="p-2 rounded-full bg-red-500 text-white mr-1 my-1 cursor-pointer hover:bg-red-600 focus:outline-none focus:bg-red-700"
              >
                <FaTrash />
              </span>
            </div>
          )}
          <div className="  ">
          {/* <button className="font-bold rounded-lg  text-sm ">
            {formatPrice(price)}
          </button> */}
            
          </div>
          
        </div>
      </div>
        
      
    </div>
  );
};

export default Product;
