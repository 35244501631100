import React from "react";
import { Link } from "react-router-dom";
import { useCartContext } from "../context/cart_context";
import CartItem from "./CartItem";
import CartTotals from "./CartTotals";
import { FiTrash } from "react-icons/fi";

const CartContent = () => {
  const { cart, clearCart } = useCartContext();
  return (
    <div className="container mx-auto mt-10">
      <div className="flex flex-wrap lg:flex-nowrap shadow-md my-10">
        <div className="w-full lg:w-3/4 bg-white px-10 lg:py-10">
          <div className="flex justify-between border-b pb-8 mb-5 lg:mb-0">
            <h1 className="font-semibold text-2xl">Shopping Cart</h1>
            <h2 className="font-semibold text-2xl">{`${cart.length} Items`}</h2>
          </div>
          <div className="hidden lg:flex mt-10 mb-5">
            <h3 className="font-semibold text-gray-600 text-xs uppercase w-2/5">
              Product Details
            </h3>
            <h3 className="font-semibold text-gray-600 text-xs uppercase w-1/5 text-center">
              Quantity
            </h3>
            <h3 className="font-semibold text-gray-600 text-xs uppercase w-1/5 text-center">
              Price
            </h3>
            <h3 className="font-semibold text-gray-600 text-xs uppercase w-1/5 text-center">
              Total
            </h3>
          </div>
          {cart.map((item) => {
            return <CartItem key={item.id} {...item} />;
          })}

          <button
            onClick={clearCart}
            className="flex gap-3 w-36 font-semibold text-red-600 text-sm mt-10"
          >
            <FiTrash />
            Clear cart
          </button>
          <Link
            to="/products"
            className="flex w-36 text-black font-bold text-sm mt-4"
          >
            <svg
              className="fill-current mr-2 text-black font-bold w-4"
              viewBox="0 0 448 512"
            >
              <path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z" />
            </svg>
            Continue Shopping
          </Link>
        </div>
        <CartTotals />
      </div>
    </div>
  );
};

export default CartContent;
