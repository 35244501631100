import React from "react";

import CardSecurity from "../../Cards/CardSecurity";

export default function Security() {
  return (
    <>
      <div className="flex justify-center mb-10">
        <div className="w-full lg:w-8/12 px-4 py-16">
          <CardSecurity />
        </div>
      </div>
    </>
  );
}
