import React, { useState } from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import { BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../../context/global_context";
import { formatPrice, convertBase64 } from "../../../utils/helpers";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useProductsContext } from "../../../context/products_context";

export default function CardUserOrder() {
  const { status } = useProductsContext();
  const [visible, setIsVisible] = useState(false);

  const { filtered_user_orders: orders } = useGlobalContext();
  const {
    user_filters: { statusFilter },
    updateUserFilters,
  } = useGlobalContext();

  const { status: stat } = status;

  const statusUpdate = ["All", ...new Set(stat)];

  const settings = {
    rows: 1,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="space-y-5">
      {visible && (
        <div
          id="small-modal"
          tabIndex="-1"
          className="overflow-y-auto overflow-x-hidden fixed top-16 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative bg-black/60 rounded-lg shadow dark:bg-gray-700">
              <div className="flex justify-between items-center p-5 rounded-t border-b dark:border-gray-600">
                <h3 className="text-xl font-medium text-white dark:text-white">
                  Orders Status
                </h3>
                <button
                  type="button"
                  className="text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => setIsVisible(false)}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <form
                className="pl-5 mt-5 space-y-5 "
                onSubmit={(e) => e.preventDefault()}
              >
                {statusUpdate?.map((stat, index) => {
                  return (
                    <button
                      key={index}
                      onClick={updateUserFilters}
                      name="statusFilter"
                      type="button"
                      value={stat}
                      className={`block my-1 mx-0 capitalize bg-transparent tracking-wider font-medium text-white cursor-pointer ${
                        statusFilter === stat &&
                        "opacity-100 border-b border-b-[#869BB0]"
                      }`}
                    >
                      {stat}
                    </button>
                  );
                })}
              </form>
              <div className="flex items-center mt-4 p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <button
                  onClick={() => setIsVisible(false)}
                  type="button"
                  className="text-white bg-green-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <h1 className="text-3xl font-semibold mb-10 py-16">Orders</h1>
      <div className="hidden bg-white shadow-md py-5 px-8 rounded-md lg:flex justify-between">
        {statusUpdate?.map((stat, index) => {
          return (
            <button
              key={index}
              onClick={updateUserFilters}
              name="statusFilter"
              type="button"
              value={stat}
              className={`block my-1 mx-0 capitalize bg-transparent tracking-wider font-medium hover:text-[#869BB0] cursor-pointer ${
                statusFilter === stat &&
                "opacity-100 border-b border-b-[#869BB0]"
              }`}
            >
              {stat}
            </button>
          );
        })}
      </div>
      <div className="flex justify-between items-center lg:hidden">
        <h1 className="text-[#A699A6] font-medium uppercase text-sm">{`Total Orders : ${orders.length}`}</h1>
        <button
          className="cursor-pointer sm:flex leading-relaxed font-medium px-6 text-[#A699A6] border tracking-widest"
          type="button"
          onClick={() => setIsVisible(true)}
        >
          Filter
        </button>
      </div>
      {orders?.map((order) => {
        return (
          <div
            key={order._id}
            className="bg-white shadow-md py-5 px-8 rounded-md"
          >
            <div className="flex justify-between">
              <div>
                <h1 className="font-bold">{order.status}</h1>
                <h1 className="text-gray-500">
                  Total items : {`${order.products.length}`}
                </h1>
              </div>
              <div className="flex mb-3">
                <div className="lg:border-r lg:px-4 lg:mr-4">
                  <h1>
                    <span className="hidden lg:inline">Order date: </span>
                    {new Date(order.createdAt).toDateString()}
                  </h1>
                  <h1 className="hidden lg:block">
                    Order ID: {order._id.substr(0, 16)}
                  </h1>
                </div>
                <div className="hidden lg:flex items-center gap-3 font-bold">
                  <Link to={`/user/orders/${order._id}`}>Order details</Link>
                  <span className="font-semibold">
                    <BsChevronRight />
                  </span>
                </div>
              </div>
            </div>
            <hr />

            <div className="lg:grid lg:grid-cols-2">

              <Slider {...settings}>
                {order?.products?.map((product, index) => {
                  return (
                    <div key={product._id} className="relative">
                      <div>
                       {/* {console.log(product.productPictures[0].img)} */}
                      <img
                         src={product?.productPictures[0]?.img}
                         alt={product.name}
                         className=" object-cover pt-2 object-center w-36 h-25"
                       />
                        {/* {product.productPictures.map((p,index)=>{
                          console.log(product.productPictures[0])
                          return(
                       
                          )})} */}               
                      </div>

                      <div className="absolute top-6 left-40">
                        <h1>{product.name}</h1>
                        <h1>{formatPrice(product.price)}</h1>
                      </div>
                    </div>
                  );
                })}
              </Slider>
              
              <div className="place-self-end text-center">
                <h1 className="mb-2">total {formatPrice(order.amount)}</h1>
                <Link
                  className="hidden lg:inline-block
                   border px-16 py-1 rounded-full font-semibold hover:bg-black hover:text-white hover:border-none"
                  to="/products"
                >
                  Shop again
                </Link>
                <Link
                  className="lg:hidden
                   border px-16 py-1 rounded-full font-semibold hover:bg-black hover:text-white hover:border-none"
                  to={`/user/orders/${order._id}`}
                >
                  Order details
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
