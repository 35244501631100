import React from "react";
import { BsFillGridFill, BsList } from "react-icons/bs";
import { useFilterContext } from "../context/filters_context";

const Sort = () => {
  const {
    filtered_products: products,
    grid_view,
    setGridView,
    setListView,
    sort,
    updateSort,
  } = useFilterContext();
  return (
    <section className="flex justify-between lg:grid grid-cols-[auto_auto_1fr] gap-y-3 md:grid-cols-[auto_auto_1fr_auto] items-center mb-8 gap-x-5">
      <div className="flex gap-2 order-last lg:order-none">
        <button
          type="button"
          className={`border border-[#222] w-6 rounded-md h-6 flex items-center justify-center cursor-pointer ${
            grid_view && "bg-black text-white"
          }`}
          onClick={setGridView}
        >
          <span>
            <BsFillGridFill />
          </span>
        </button>
        <button
          type="button"
          className={`border border-[#222] w-6 rounded-md h-6 flex items-center justify-center cursor-pointer ${
            !grid_view && "text-white bg-black"
          }`}
          onClick={setListView}
        >
          <span>
            <BsList />
          </span>
        </button>
      </div>
      <p className="hidden lg:block capitalize mb-0">{`${products.length} products found`}</p>
      <form className="md:place-self-end">
        <label
          className="inline-block uppercase font-medium md:text-lg text-sm"
          htmlFor="sort"
        >
          sort by:
        </label>
        <select
          name="sort"
          id="sort"
          className="border-none font-medium rounded-md capitalize py-1 focus:border-blue-400 focus:ring-blue-400"
          value={sort}
          onChange={updateSort}
        >
          <option value="price-lowest">price (lowest)</option>
          <option value="price-highest">price (highest)</option>
          <option value="name-a">price (a-z)</option>
          <option value="name-z">price (z-a)</option>
        </select>
      </form>
    </section>
  );
};

export default Sort;
