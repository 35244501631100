import React from "react";
import { useState } from "react";

const Whatsapp = () => {
  const [visible, setIsVisible] = useState(false);
  return (
    <div>
      <div>
        <a
          href="https://wa.me/+212677740344"
          className="fixed w-[50px] h-[50px] bottom-16 right-[10px] md:bottom-[70px] md:right-[40px] bg-black text-white rounded-full text-center text-[2rem] shadow-md z-[10000]"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-whatsapp whatsapp-icon mt-2"></i>
        </a>
      </div>
      <button
        className="fixed w-[50px] h-[50px] bottom-[10px] right-[10px] md:bottom-[10px] md:right-[40px] bg-black text-white rounded-full text-center text-2xl shadow-md z-[10000]"
        onClick={() => setIsVisible(true)}
      >
        <i className="fa fa-share"></i>
      </button>
      {visible && (
        <div
          id="small-modal"
          tabindex="-1"
          class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full grid place-items-center"
        >
          <div class="relative p-4 w-full max-w-md h-full md:h-auto">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div class="flex justify-between items-center p-5 rounded-t border-b dark:border-gray-600">
                <h3 class="text-xl font-medium text-gray-900 dark:text-white">
                  Share This Website
                </h3>
                <button
                  type="button"
                  class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => setIsVisible(false)}
                >
                  <svg
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <div class="p-6 space-y-3">
                <h1 class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  share this link via :
                </h1>
                <ul class="icons flex justify-between">
                  <a
                    href="https://www.facebook.com/dialog/share?app_id=202127659076&display=popup&href=https://nutrisport-morocco.herokuapp.com/"
                    rel="noreferrer"
                    target="_blank"
                    className="text-xl border border-solid border-transparent"
                  >
                    <i class="fa fa-facebook-f text-[#1877F2] border border-[#b7d4fb] hover:bg-[#1877F2] hover:text-white hover:border-transparent hover:scale-110 transition-all duration-500 ease-in-out rounded-full h-12 w-12 flex items-center justify-center"></i>
                  </a>
                  <a
                    rel="noreferrer"
                    href="https://twitter.com/intent/tweet?related=nutrisport&text=Check+out+our+store+on+Nutrisport%3A+Better+Protein+Better+Health&url=https://nutrisport-morocco.herokuapp.com/"
                    target="_blank"
                    className="h-12 w-12 text-xl border border-solid border-transparent"
                  >
                    <i class="fa fa-twitter text-[#46C1F6] border border-[#b6e7fc] hover:bg-[#46C1F6] hover:text-white hover:border-transparent hover:scale-110 transition-all duration-500 ease-in-out  py-2 px-3 rounded-full h-12 w-12 flex items-center justify-center"></i>
                  </a>
                  <a
                    href="/"
                    className="h-12 w-12 text-xl border border-solid border-transparent"
                  >
                    <i class="fa fa-instagram text-[#e1306c] border border-[#f5bccf] hover:bg-[#e1306c] hover:text-white hover:border-transparent hover:scale-110 transition-all duration-500 ease-in-out  py-2 px-3 rounded-full h-12 w-12 flex items-center justify-center"></i>
                  </a>
                  <a
                    href="https://api.whatsapp.com/send/?text=Check+it+out%21+%23+MadeOnNutrisport+https://nutrisport-morocco.herokuapp.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="h-12 w-12 text-xl border border-solid border-transparent"
                  >
                    <i class="fa fa-whatsapp text-[#25D366] border border-[#bef4d2] hover:bg-[#25D366] hover:text-white  hover:border-transparent hover:scale-110 transition-all duration-500 ease-in-out  py-2 px-3 rounded-full h-12 w-12 flex items-center justify-center"></i>
                  </a>
                </ul>
                <p>Or copy link</p>
                <div
                  class="h-11 rounded py-0 px-1 border border-solid border-[#e1e1e1] flex
                "
                >
                  <i class="w-12 text-xl text-center text-[#7d2ae8]"></i>
                  <input
                    className="w-full h-full border-none outline-none text-base"
                    type="text"
                    readonly
                    value="https://nutrisport-morocco.herokuapp.com/"
                  />
                  <button className="py-1 px-4 bg-black text-white my-1 rounded-md">
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Whatsapp;
