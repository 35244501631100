import React, { useEffect } from "react";
import { useProductsContext } from "../../../../context/products_context";
import { useUsersContext } from "../../../../context/users_context";
import CardUserOrder from "../../Cards/CardUserOrder";
import CardAccount from "../../Cards/CardAccount";
import CardPageVisits from "../../Cards/CardPageVisits";
import Ecommerce from "../admin/Ecommerce";

export default function UserOrders() {
  const { getUserOrders, getStatus } = useProductsContext();
  const { user, token } = useUsersContext();

  useEffect(() => {
    getUserOrders(user._id, token);
    getStatus(user._id, token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="w-full mb-12 px-4">
        <CardUserOrder />
      </div>
    </>
  );
}
