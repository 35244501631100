import React, { useState } from "react";
import { useCategoriesContext } from "../context/categories_context";
import { useFilterContext } from "../context/filters_context";
import { formatPrice, getUniqueValues } from "../utils/helpers";

const Filters = () => {
  const [visible, setIsVisible] = useState(false);
  const {
    filters: { text, category, min_price, price, max_price },
    updateFilters,
    clearFilters,
  } = useFilterContext();

  const { filtered_products: products } = useFilterContext();

  const { all_categories } = useCategoriesContext();

  const categories = getUniqueValues(all_categories);

  return (
    <div>
      {visible && (
        <div
          id="small-modal"
          tabIndex="-1"
          className="overflow-y-auto overflow-x-hidden fixed top-16 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative bg-black/60 rounded-lg shadow dark:bg-gray-700">
              <div className="flex justify-between items-center p-5 rounded-t border-b dark:border-gray-600">
                <h3 className="text-xl font-medium text-white dark:text-white">
                  Filters
                </h3>
                <button
                  type="button"
                  className="text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => setIsVisible(false)}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <form className="pl-5 mt-5 " onSubmit={(e) => e.preventDefault()}>
                {/* categories */}
                <div className="mb-5">
                  <h5 className="mb-2 text-white font-bold">Category</h5>
                  <div>
                    {categories.map((c, index) => {
                      return (
                        <button
                          key={index}
                          onClick={updateFilters}
                          name="category"
                          type="button"
                          value={c._id}
                          className={`block my-1 mx-0 capitalize bg-transparent border-b-2 border-b-transparent tracking-wider font-medium text-white cursor-pointer ${
                            category === c._id &&
                            "underline opacity-100 border-[#869BB0]"
                          }`}
                        >
                          {c.name}
                        </button>
                      );
                    })}
                  </div>
                </div>
                {/* end of categories */}
                {/* price */}
                <div className="mb-5 ">
                  <h5 className="mb-2 text-white font-semibold">Price</h5>
                  <p className="text-white font-medium">{formatPrice(price)}</p>
                  <input
                    type="range"
                    className="accent-white"
                    name="price"
                    value={price}
                    min={min_price}
                    max={max_price}
                    onChange={updateFilters}
                  />
                </div>
                {/* end of price */}
                {/* shipping */}
              </form>
              <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <button
                  onClick={() => setIsVisible(false)}
                  type="button"
                  className="text-white bg-green-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Apply
                </button>
                <button
                  onClick={clearFilters}
                  type="button"
                  className="text-white bg-red-600 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="md:sticky top-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* search input */}
          <div className="mb-5">
            <input
              type="text"
              name="text"
              value={text}
              onChange={updateFilters}
              placeholder="search"
              className=" border-gray-300 p-2  w-full rounded-lg shadow-sm focus:border-blue-400 focus:ring-blue-400"
            />
          </div>
          <div className="flex justify-between items-center lg:hidden">
            <h1 className="text-[#A699A6] font-medium uppercase text-sm">{`Showing ${products.length} products`}</h1>
            <button
              className="cursor-pointer sm:flex leading-relaxed font-medium px-6 text-[#A699A6] border tracking-widest"
              type="button"
              onClick={() => setIsVisible(true)}
            >
              Filter
            </button>
          </div>
          {/* end search input */}
          {/* categories */}
          <div className="mb-5 hidden lg:block">
            <h5 className="mb-2 text-[#102A42] font-semibold">Choose Your Category</h5>
            <div>
            {/* update categories button */}
              {categories.map((c, index) => {
                return (
                  <div key={index} className="flex gap-x-2 items-center">
                  <input
                    onClick={updateFilters}
                    name="category"
                    type="checkbox"
                    value={c._id}
                    className={`${category===c._id ?
                       "w-4 h-4 text-black bg-gray-100 border-gray-300 rounded focus:ring-black  focus:ring-2" 
                       : "bg-white text-gray-500"} border rounded h-4 w-4`}
                  >
                   
                  </input>
                 <button 
                  //  onClick={updateFilters}
                   name="category"
                   type="button"
                   value={c._id}
                   className="block  my-1 mx-0 capitalize bg-transparent border-b-2 border-b-transparent  tracking-wider font-bold text-gray-600 cursor-pointer"
                 >
                 {c.name}
                 </button>
                  </div>
          
                   

                );
              })}
 
 
 {/* Test of dropdown button */}
   

{/* <button id="dropdownSearchButton" data-dropdown-toggle="dropdownSearch" className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-black rounded-lg hover:bg-neutral-700 focus:ring-2 focus:outline-none focus:ring-blue-300 " type="button"> Protein Categories <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
  </svg></button>


<div id="category" className="z-10 hidden bg-white rounded-lg shadow w-60 dark:bg-gray-700">
    <ul class="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
      <li>
        <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
          <input id="checkbox-item-11" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
          <label for="checkbox-item-11" class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Bonnie Green</label>
        </div>
      </li>
    </ul>

     {categories.map((c, index) => {
                return (
                  <div key={index} className="flex gap-x-2 items-center">
                  <button
                    onClick={updateFilters}
                    name="category"
                    type="chek-box"
                    value={c._id}
                    // className={`block my-1 mx-0 capitalize bg-transparent border-b-2 border-b-transparent tracking-wider font-medium text-[#869BB0] cursor-pointer ${
                    //   category === c._id &&
                    //   "underline opacity-100 border-[#869BB0]"
                    // }`}
                    className={`${category===c._id ?
                       "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-black  focus:ring-2" 
                       : "bg-white"} border rounded h-4 w-4`}
                  >
                   
                  </button>
                 <button 
                   onClick={updateFilters}
                   name="category"
                   type="button"
                   value={c._id}
                   className="block  my-1 mx-0 capitalize bg-transparent border-b-2 border-b-transparent  tracking-wider font-bold text-gray-600 cursor-pointer"
                 >
                 {c.name}
                 </button>
                  </div>
          
                );
              })}
  
</div> */}












            </div>
          </div>
          {/* end of categories */}
          {/* price */}
          <div className="mb-5 hidden lg:block">
            <h5 className="mb-2 text-[#102A42] font-semibold">Price</h5>
            <p className="text-[#869BB0] font-medium">{formatPrice(price)}</p>
            <input
              type="range"
              className="accent-black"
              name="price"
              value={price}
              min={min_price}
              max={max_price}
              onChange={updateFilters}
            />
          </div>
          {/* end of price */}
          {/* shipping */}
        </form>
        <button
          type="button"
          onClick={clearFilters}
          className="hidden lg:block bg-red-500 text-white py-1 px-2 rounded-md capitalize tracking-wide hover:bg-red-600 transition-all duration-200 ease-linear"
        >
          clear filters
        </button>
      </div>
    </div>
  );
};

export default Filters;
