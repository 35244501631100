import React from "react";

export default function Newsletter() {
  return (
    <div className="2xl:mx-auto 2xl:container ">
      <div className=" w-full  relative flex items-center justify-center">
        <div className="mb-10 lg:py-16 py-10 w-full md:mx-24 md:px-12 px-4 flex flex-col items-center justify-center">
          <h1 className="text-xl lg:text-4xl font-semibold leading-9 text-center">
            SIGN UP FOR WEEKLY OFFERS, NEWS AND ADVICE
          </h1>
          <p className="text-base leading-normal text-center mt-6">
            Plus 15% off your first order
          </p>
          <div className="sm:border flex-col sm:flex-row flex items-center lg:w-5/12 w-full mt-12 space-y-4 sm:space-y-0">
            <input
              className="text-base w-full font-medium leading-none p-4"
              placeholder="Email Address"
            />
            <button className="w-full sm:w-auto py-4 px-6 bg-black tracking-wider text-white font-semibold">
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
