import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div className="flex flex-wrap lg:flex-nowrap mt-10">
      <div className="w-full h-64 cursor-pointer bg-banner1 bg-cover bg-no-repeat bg-center">
        <div className="text-center bg-black bg-opacity-0 w-full h-full hover:bg-opacity-50 transition-all duration-300 grid place-items-center">
          <div>
            <h1 className="text-2xl uppercase mb-5 text-white font-bold">
              High Quality Protein
            </h1>
            <Link
              to="/products"
              className="inline-block px-8 py-3 bg-black uppercase font-semibold text-white"
            >
              explore
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full h-64 cursor-pointer bg-banner2 bg-cover bg-no-repeat bg-center">
        <div className="text-center bg-black bg-opacity-0 w-full h-full hover:bg-opacity-50 transition-all duration-300 grid place-items-center">
          <div>
            <h1 className="text-2xl uppercase mb-5 text-white font-bold">
              different taste
            </h1>
            <Link
              to="/products"
              className="inline-block px-8 py-3 bg-black uppercase font-semibold text-white"
            >
              explore
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full h-64 cursor-pointer bg-banner3 bg-cover bg-no-repeat bg-center">
        <div className="text-center bg-black bg-opacity-0 w-full h-full hover:bg-opacity-50 transition-all duration-300 grid place-items-center">
          <div>
            <h1 className="text-2xl uppercase mb-5 text-white font-bold">
              More energy
            </h1>
            <Link
              to="/products"
              className="inline-block px-8 py-3 bg-black uppercase font-semibold text-white"
            >
              explore
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
