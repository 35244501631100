import React from "react";
// import Orders from "./Orders";
import { StartDashboard } from "./StartDashboard";
// import { TransactionCharts } from "./TransactionCharts";
import { LatestOrders } from "./LatestOrders";
import TransactionNew from "./TransactionNew";






const Ecommerce = () => {
  
  return (
     <div className="flex gap-4 justify-center flex-col">
       <StartDashboard/>
       <TransactionNew/>
       <LatestOrders/>
       {/* <TransactionCharts/>
       <LatestOrders/> */}
      
    </div>
      
    
  );
};

export default Ecommerce;
  