import React from "react";
import { FaTimes } from "react-icons/fa";
import { FiLogOut, FiUserPlus } from "react-icons/fi";
import { BiHome, BiBarChart, BiPackage, BiFoodMenu } from "react-icons/bi";

import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
// import CartButtons from "./CartButtons";
import { useGlobalContext } from "../context/global_context";
import { useUsersContext } from "../context/users_context";
import { useCartContext } from "../context/cart_context";

const Sidebar = () => {
  const { sidebar_open, closeSidebar } = useGlobalContext();
  const { user, logoutUser } = useUsersContext();
  const { clearCart } = useCartContext();

  return (
    <>
      <div
        className={`lg:hidden top-0 left-0 w-[60vw] bg-white shadow-2xl pt-14 fixed h-full z-40  ease-in-out duration-300 ${
          sidebar_open ? "translate-x-0 " : "-translate-x-full"
        }`}
      >
        {sidebar_open && (
          <button
            className="absolute top-2 right-2 text-2xl items-center cursor-pointer"
            onClick={closeSidebar}
          >
            <FaTimes />
          </button>
        )}
        <ul className="space-y-2">
          <li className="w-full">
            <Link
              onClick={closeSidebar}
              to="/"
              className="flex items-center p-2 text-base font-normal text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <span className="text-2xl">
                <BiHome />
              </span>
              <span className="ml-3">Home</span>
            </Link>
          </li>
          <li>
            <Link
              onClick={closeSidebar}
              to="/products"
              className="flex items-center p-2 text-base font-normal text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <span className="text-2xl">
                <BiPackage />
              </span>
              <span className="flex-1 ml-3 whitespace-nowrap">Products</span>
            </Link>
          </li>
          <li>
            <Link
              onClick={closeSidebar}
              to={user?.role === 1 ? "/admin/dashboard" : "/user/dashboard"}
              className="flex items-center p-2 text-base font-normal text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <span className="text-2xl">
                <BiBarChart />
              </span>
              <span className="flex-1 ml-3 whitespace-nowrap">Dashboard</span>
            </Link>
          </li>
          <li>
            <Link
              onClick={closeSidebar}
              to="/contactus"
              className="flex items-center p-2 text-base font-normal text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <span className="text-2xl">
                <BiFoodMenu />
              </span>
              <span className="flex-1 ml-3 whitespace-nowrap">Contact us</span>
            </Link>
          </li>
        </ul>
        <ul className="pt-4 mt-4 space-y-2 border-t border-gray-200 dark:border-gray-700">
          {user ? (
            <li className="w-full">
              <Link
                to="/"
                onClick={() => {
                  clearCart();
                  logoutUser();
                }}
                className="flex items-center p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group"
              >
                <span className="text-2xl">
                  <FiLogOut />
                </span>
                <span className="flex-1 ml-3 whitespace-nowrap">Logout</span>
              </Link>
            </li>
          ) : (
            <li className="w-full">
              <Link
                onClick={closeSidebar}
                to="/signin"
                className="flex items-center p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group"
              >
                <span className="text-2xl">
                  <FiUserPlus />
                </span>
                <span className="flex-1 ml-3 whitespace-nowrap">Sign in</span>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
