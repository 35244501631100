import React from "react";
import Product from "./Product";

const GridView = ({ products }) => {
  return (
    <section>
      <div className="grid gap-y-8 gap-x-8 md:grid-cols-[1fr,1fr,1fr] lg:grid-cols-[1fr,1fr,1fr]">
        {products.map((product) => {
          return <Product key={product._id} {...product} />;
        })}
      </div>
    </section>
  );
};

export default GridView;
