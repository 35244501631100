import React from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ShowPassword = ({ togglePassword, passwordShown }) => {
  return (
    <span className=" -ml-8 text-lg cursor-pointer" onClick={togglePassword}>
      {!passwordShown ? <FaEye /> : <FaEyeSlash />}
    </span>
  );
};

export default ShowPassword;
