import React, { useContext, useReducer } from "react";
import reducer from "../reducers/users_reducer";
import {
  REGISTER_USER_BEGIN,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGIN_USER_BEGIN,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  CLEAR_ALERT,
  DISPLAY_ALERT,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  LOGOUT_USER,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_BEGIN,
} from "../actions";
import axios from "axios";
import { API_URL, headersConfig } from "../utils/constants";

const token = localStorage.getItem("token");
const user = localStorage.getItem("user");

const initialState = {
  isLoading: false,
  showAlert: false,
  alertText: "",
  alertType: "",
  user: user ? JSON.parse(user) : null,
  token: token,
  users: [],
};

const UsersContext = React.createContext();

export const UsersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const displayAlert = (msg) => {
    dispatch({ type: DISPLAY_ALERT, payload: msg });
    clearAlert();
  };

  const clearAlert = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
    }, 3000);
  };

  const addUserToLocalStorage = ({ user, token }) => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token);
  };
  const removeUserFromLocalStorage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  const loginUser = async (currentUser) => {
    dispatch({ type: LOGIN_USER_BEGIN });
    try {
      const response = await axios.post(`${API_URL}/auth/login`, currentUser);
      const { user, token } = response.data;

      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: { user, token },
      });

      addUserToLocalStorage({ user, token });
    } catch (error) {
      dispatch({
        type: LOGIN_USER_ERROR,
        payload: { msg: error.response.data.error },
      });
    }
    clearAlert();
  };

  const registerUser = async (registredUser) => {
    dispatch({ type: REGISTER_USER_BEGIN });
    try {
      const response = await axios.post(
        `${API_URL}/auth/register`,
        registredUser
      );
      const { user, token } = response.data;
      dispatch({
        type: REGISTER_USER_SUCCESS,
        payload: { user, token },
      });

      addUserToLocalStorage({ user, token });
    } catch (error) {
      dispatch({
        type: REGISTER_USER_ERROR,
        payload: { msg: error.response.data.error },
      });
    }
    clearAlert();
  };

  const logoutUser = async () => {
    await axios.get(`${API_URL}/auth/signout`);
    dispatch({ type: LOGOUT_USER });
    removeUserFromLocalStorage();
  };

  const forgetPassword = async (email) => {
    try {
      const res = await axios.post(`${API_URL}/auth/forgotpassword`, {
        email,
      });
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
      });
      console.log(res);
    } catch (error) {
      dispatch({
        type: FORGOT_PASSWORD_ERROR,
        payload: { msg: error.response.data.error },
      });
    }
    clearAlert();
  };
  const resetPassword = async (resettoken, password) => {
    try {
      await axios.put(`${API_URL}/auth/passwordreset/${resettoken}`, {
        password,
      });
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: RESET_PASSWORD_ERROR,
        payload: { msg: error.response.data.error },
      });
    }
    clearAlert();
  };

  const updateAccount = async (id, fd) => {
    try {
      const response = await axios.patch(
        `${API_URL}/auth/updateProfile/${id}`,
        fd,
        headersConfig(token)
      );
      console.log(response);
      const { user } = response.data;

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user },
      });
      addUserToLocalStorage({ user, token });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_ERROR,
        payload: { msg: "error" },
      });
    }

    clearAlert();
  };

  const changePassword = async (id, token, currentPassword, password) => {
    try {
      const response = await axios.patch(
        `${API_URL}/auth/updateMyPassword/${id}`,
        { currentPassword, password },
        headersConfig(token)
      );
      const { user } = response.data;
      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: { user },
      });
      addUserToLocalStorage({ user, token });
    } catch (error) {
      dispatch({
        type: CHANGE_PASSWORD_ERROR,
        payload: { msg: error.response.data.error },
      });
    }
    clearAlert();
  };

  const getAllUsers = async (id) => {
    dispatch({
      type: GET_ALL_USERS_BEGIN,
    });
    try {
      const response = await axios.get(
        `${API_URL}/user/getallusers/${id}`,
        headersConfig(token)
      );
      const { users } = response.data.data;
      dispatch({
        type: GET_ALL_USERS_SUCCESS,
        payload: users,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <UsersContext.Provider
      value={{
        ...state,
        displayAlert,
        registerUser,
        loginUser,
        logoutUser,
        forgetPassword,
        resetPassword,
        updateAccount,
        changePassword,
        getAllUsers,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export const useUsersContext = () => {
  return useContext(UsersContext);
};
