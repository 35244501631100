import React from "react";
import { Link } from "react-router-dom";
import { footerLinks } from "../utils/constants";

const Footer = () => {
  return (
    <footer className="border-t border-gray-200 bg-white py-8 lg:py-12">
      <div className="grid place-items-center grid-cols-1">
        <ul className="text-center space-y-3 lg:space-y-0 lg:text lg:flex gap-12 uppercase font-light text-black">
          {footerLinks.map((link) => {
            return (
              <li key={link.id}>
                <Link to={link.id}>{link.text}</Link>
              </li>
            );
          })}
        </ul>
        <div className="mt-5 mb-2">
          <p className="text-base font-light">{`© ${new Date().getFullYear()} NutriSport. All Rights Reserved.`}</p>
        </div>
        <p className="text-xs text-center font-light">
          Created by Imad Azza 
        </p>
      </div>
    </footer>
  );
};

export default Footer;
