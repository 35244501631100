import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUsersContext } from "../../../context/users_context";
import { convertBase64 } from "../../../utils/helpers";
import Alert from "../../Alert";
import imgDefault from "../../../assets/images/default.jpg";
import { AiOutlineCloudUpload } from "react-icons/ai";

// components

export default function CardAccount() {
  const { user, displayAlert, updateAccount, showAlert } = useUsersContext();

  const { _id, name, email } = user;
  const [newName, setNewName] = useState(name);
  const [newEmail, setNewEmail] = useState(email);
  const [newPhoto, setNewPhoto] = useState();
  const [fd] = useState(new FormData());

  useEffect(() => {
    fd.set("photo", newPhoto);
    fd.set("name", newName);
    fd.set("email", newEmail);
  }, [newName, newEmail, newPhoto]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newName || !newEmail) {
      displayAlert("please Provide all values");
      return;
    }
    updateAccount(_id, fd);
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 lg:mb-10 shadow-lg rounded-lg bg-gray-50 border-0">
        <div className="rounded-t bg-white mb-0 px-4 py-6 ">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-600 tracking-wider text-xl font-bold ">
              My account
            </h6>
          </div>
        </div>
        <div className="flex-auto px-6 lg:px-10 py-10 pt-0">
          <form onSubmit={handleSubmit}>
            <h6 className="text-gray-400 tracking-wider text-sm mt-3 mb-6 font-bold uppercase">
              Need to update your profile?
              <Link to="/profile" className="text-[#B5942F] hover:underline">
                Go to My Profile
              </Link>
            </h6>

            {showAlert && (
              <div className="mt-3">
                <Alert />
              </div>
            )}
            <div className="flex relative flex-wrap flex-col">
              <div className="w-full lg:w-6/12 px-4">
                <div className="py-3 flex justify-end md:absolute md:right-0 md:-top-10">
                  <div className="rounded-lg text-center">
                    <label className="cursor-pointer mt-6">
                      <div className="relative w-[110px] hover:brightness-75">
                        <img
                          className="rounded-full block w-full object-cover"
                          src={
                            user?.photo
                              ? `data:image/jpeg;base64,${convertBase64(
                                  user?.photo?.data?.data
                                )}`
                              : imgDefault
                          }
                          alt="Avatar Upload"
                        />
                        <span className="text-4xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 cursor-pointer hover:opacity-100 hover:brightness-100 text-white">
                          <AiOutlineCloudUpload />
                        </span>
                      </div>
                      <input
                        type="file"
                        onChange={(e) => setNewPhoto(e.target.files[0])}
                        className="hidden"
                        multiple="multiple"
                        accept="accept"
                      />
                    </label>
                  </div>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase tracking-wider text-gray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    className="w-full border-gray-300 rounded-lg shadow-sm focus:border-blue-400 focus:ring-blue-400"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full">
                  <label
                    className="block uppercase tracking-wider text-gray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    className="w-full border-gray-300 rounded-lg shadow-sm focus:border-blue-400 focus:ring-blue-400"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-6 px-4 md:px-0">
              <button
                className="bg-[#B5942F] tracking-widest text-white active:bg-[#a3852a] font-bold uppercase text-sm px-5 py-3 rounded-lg shadow hover:shadow-md hover:bg-[#a3852a] outline-none focus:outline-none ease-linear transition-all duration-150"
                type="submit"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

//  {
//    /* for multer disc storage
//       <img
//         className="w-full object-contain hover:scale-[1.2] overflow-hidden transition-all duration-700 ease-linear"
//         src={`http://localhost:8000/${user?.photo.substring(15)}`}
//         alt=""
//       /> */
//  }
