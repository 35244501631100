import React from "react";
import { Link } from "react-router-dom";
import { useCartContext } from "../context/cart_context";
import { useUsersContext } from "../context/users_context";
import { formatPrice } from "../utils/helpers";

const CartTotals = () => {
  const { total_amount, shipping_fee, cart } = useCartContext();
  const { token } = useUsersContext();

  return (
    <div id="summary" className="w-full lg:w-1/4 px-8 py-10">
      <h1 className="font-semibold text-2xl border-b pb-8">Order Summary</h1>
      <div className="flex justify-between mt-10 mb-5">
        <span className="font-semibold text-sm uppercase">{`Items ${cart.length}`}</span>
        <span className="font-semibold text-sm">
          {formatPrice(total_amount)}
        </span>
      </div>
      <div>
        <label className="font-medium inline-block mb-3 text-sm uppercase">
          Shipping
        </label>
        <select className="block border-gray-300 rounded-lg shadow-sm focus:border-blue-400 focus:ring-blue-400">
          <option>{`Standard shipping - ${formatPrice(shipping_fee)} `}</option>
        </select>
      </div>
      <div className="border-t mt-8">
        <div className="flex font-semibold justify-between py-6 text-sm uppercase">
          <span>Total cost</span>
          <span>{formatPrice(total_amount + shipping_fee)}</span>
        </div>
        {token ? (
          <Link
            to="/checkout"
            className="inline-block text-center bg-black font-semibold hover:bg-gray-700 py-3 text-sm text-white uppercase w-full"
          >
            Checkout
          </Link>
        ) : (
          <Link
            to="/signin"
            className="inline-block text-center bg-black font-semibold hover:bg-gray-700 py-3 text-sm text-white uppercase w-full"
          >
            sign in
          </Link>
        )}
      </div>
    </div>
  );
};

export default CartTotals;
