import React from 'react'
import { Navigate } from 'react-router-dom';
import { useUsersContext } from '../context/users_context'
import { parseJwt } from '../utils/helpers'

const ProtectedAdminRoute = ({children}) => {
    const {token}  = useUsersContext();
    const userInfo = parseJwt(token);
    if(!token){
        return <Navigate to='/' />
    }
   
     if(userInfo.role === 0) {
         return <Navigate to='/' />
     }
  
  return children
}

export default ProtectedAdminRoute