import {
  CLEAR_ALERT,
  DELETE_PRODUCT_SUCCESS,
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_STATUS_SUCCESS,
  GET_ONE_ORDER_SUCCESS,
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_ERROR,
  GET_PRODUCTS_SUCCESS,
  GET_SINGLE_PRODUCT_BEGIN,
  GET_SINGLE_PRODUCT_ERROR,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_USER_ORDERS_SUCCESS,
  IS_EDITING,
  IS_NOT_EDITING,
  PAYMENT_FAILED,
  PAYMENT_SUCCESS,
  TOKEN_SUCCESS,
} from "../actions";

const products_reducer = (state, action) => {
  if (action.type === TOKEN_SUCCESS) {
    return { ...state, tokenBrain: action.payload };
  }
  if (action.type === IS_EDITING) {
    return { ...state, isEditing: true };
  }
  if (action.type === IS_NOT_EDITING) {
    return { ...state, isEditing: false };
  }
  if (action.type === GET_PRODUCTS_BEGIN) {
    return { ...state, products_loading: true };
  }
  if (action.type === GET_PRODUCTS_SUCCESS) {
    // const featured_products = action.payload.filter(
    //   (product) => product.featured === true
    // );
    return {
      ...state,
      products_loading: false,
      products: action.payload,
    };
  }
  if (action.type === GET_PRODUCTS_ERROR) {
    return { ...state, products_loading: false, products_error: true };
  }
  if (action.type === DELETE_PRODUCT_SUCCESS) {
    return {
      ...state,
      showAlert: true,
      alertType: "success",
      alertText: "Product deleted successfully",
    };
  }
  if (action.type === GET_SINGLE_PRODUCT_BEGIN) {
    return {
      ...state,
      single_product_loading: true,
      single_product_error: false,
    };
  }
  if (action.type === GET_SINGLE_PRODUCT_SUCCESS) {
    return {
      ...state,
      single_product_loading: false,
      single_product: action.payload,
    };
  }
  if (action.type === GET_SINGLE_PRODUCT_ERROR) {
    return {
      ...state,
      single_product_loading: false,
      single_product_error: true,
    };
  }
  if (action.type === PAYMENT_SUCCESS) {
    return {
      ...state,
      showAlert: true,
      alertType: "success",
      alertText: "Payment success ! redirecting to products page...",
    };
  }
  if (action.type === PAYMENT_FAILED) {
    return {
      ...state,
      showAlert: true,
      alertType: "danger",
      alertText: "Payment failed",
    };
  }
  if (action.type === GET_ALL_ORDERS_SUCCESS) {
    return {
      ...state,
      orders: action.payload,
    };
  }

  if (action.type === GET_ONE_ORDER_SUCCESS) {
    return {
      ...state,
      single_order: action.payload,
    };
  }

  if (action.type === GET_USER_ORDERS_SUCCESS) {
    return {
      ...state,
      user_orders: action.payload,
    };
  }

  if (action.type === GET_ALL_STATUS_SUCCESS) {
    return {
      ...state,
      status: action.payload,
    };
  }
  if (action.type === CLEAR_ALERT) {
    return {
      ...state,
      showAlert: false,
      alertType: "",
      alertText: "",
    };
  }
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default products_reducer;
