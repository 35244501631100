import React from "react";
function ProgressStatus({ status }) {
  return (
    <>
      <div className="w-11/12 lg:w-5/6 mx-auto">
        <div className="bg-gray-200 h-1 flex items-center justify-between">
          <div
            className={`w-4/5 h-1 flex items-center ${
              status === "Order placed" ? "" : "bg-indigo-900"
            }`}
          >
            <div className="bg-[#ED6231] h-6 w-6 rounded-full shadow flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-check"
                width={18}
                height={18}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#FFFFFF"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
            </div>
          </div>
          <div
            className={`w-4/5 h-1 flex items-center ${
              status === "Shipped" || status === "Delivered"
                ? "bg-indigo-900"
                : ""
            }`}
          >
            {status === "Processing" ? (
              <div className="bg-white h-6 w-6 rounded-full shadow flex items-center justify-center -mr-3 relative">
                <div className="h-3 w-3 bg-[#FABC04] rounded-full" />
              </div>
            ) : status === "Shipped" || status === "Delivered" ? (
              <div>
                <div className="absolute right-0 -mr-2">
                  <div className="relative bg-white shadow-lg px-2 py-1 rounded mt-16 -mr-12">
                    <svg
                      className="absolute top-0 -mt-1 w-full right-0 left-0"
                      width="16px"
                      height="8px"
                      viewBox="0 0 16 8"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Progress-Bars"
                          transform="translate(-322.000000, -198.000000)"
                          fill="#FFFFFF"
                        >
                          <g
                            id="Group-4"
                            transform="translate(310.000000, 198.000000)"
                          >
                            <polygon id="Triangle" points="20 0 28 8 12 8" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="bg-[#FABC04] h-6 w-6 rounded-full shadow flex items-center justify-center -ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-check"
                    width={18}
                    height={18}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#FFFFFF"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M5 12l5 5l10 -10" />
                  </svg>
                </div>
              </div>
            ) : (
              <div className="w-3/3 flex justify-end">
                <div className="bg-white h-6 w-6 rounded-full shadow" />
              </div>
            )}
          </div>
          <div
            className={`w-4/5 h-1 flex items-center ${
              status === "Delivered" ? "bg-indigo-900" : ""
            }`}
          >
            {status === "Shipped" ? (
              <div className="bg-white h-6 w-6 rounded-full shadow flex items-center justify-center -mr-3 relative">
                <div className="h-3 w-3 bg-[#3AA994] rounded-full" />
              </div>
            ) : status === "Delivered" ? (
              <div>
                <div className="absolute right-0 -mr-2">
                  <div className="relative bg-white shadow-lg px-2 py-1 rounded mt-16 -mr-12">
                    <svg
                      className="absolute top-0 -mt-1 w-full right-0 left-0"
                      width="16px"
                      height="8px"
                      viewBox="0 0 16 8"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Progress-Bars"
                          transform="translate(-322.000000, -198.000000)"
                          fill="#FFFFFF"
                        >
                          <g
                            id="Group-4"
                            transform="translate(310.000000, 198.000000)"
                          >
                            <polygon id="Triangle" points="20 0 28 8 12 8" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="bg-[#3AA994] h-6 w-6 rounded-full shadow flex items-center justify-center -ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-check"
                    width={18}
                    height={18}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#FFFFFF"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M5 12l5 5l10 -10" />
                  </svg>
                </div>
              </div>
            ) : (
              <div className="w-3/3 flex justify-end">
                <div className="bg-white h-6 w-6 rounded-full shadow" />
              </div>
            )}
          </div>
          <div>
            {status === "Delivered" ? (
              <div>
                <div className="absolute right-0 -mr-2">
                  <div className="relative bg-white shadow-lg px-2 py-1 rounded mt-16 -mr-12">
                    <svg
                      className="absolute top-0 -mt-1 w-full right-0 left-0"
                      width="16px"
                      height="8px"
                      viewBox="0 0 16 8"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Progress-Bars"
                          transform="translate(-322.000000, -198.000000)"
                          fill="#FFFFFF"
                        >
                          <g
                            id="Group-4"
                            transform="translate(310.000000, 198.000000)"
                          >
                            <polygon id="Triangle" points="20 0 28 8 12 8" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="bg-[#34d399] h-6 w-6 rounded-full shadow flex items-center justify-center -ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-check"
                    width={18}
                    height={18}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#FFFFFF"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M5 12l5 5l10 -10" />
                  </svg>
                </div>
              </div>
            ) : (
              <div className="w-3/3 flex justify-end">
                <div className="bg-white h-6 w-6 rounded-full shadow" />
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-between mt-8">
          <h1 className="text-[#ED6231] font-bold">Order placed</h1>
          <h1 className="-translate-x-10 -translate-y-20 md:translate-y-0 text-[#FABC04] font-bold">
            Processing
          </h1>
          <h1 className="-translate-x-2 text-[#3AA994] font-bold">Shiped</h1>
          <h1 className="md:translate-x-8 -translate-y-20 md:translate-y-0 text-[#34d399] font-bold">
            Delivered
          </h1>
        </div>
      </div>
    </>
  );
}
export default ProgressStatus;
